import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import InvoiceItemsTable from './InvoiceItemsTable'
import logo from '../black_mpl-logo.png'

const styles = StyleSheet.create({
    page: {
     padding:1
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },

    logo: {
        width: 80,
        height: 40,
    },
    barcode: {
        width: 130,
        height: 20
    }
});


const Invoice = ({ invoice }) => {

    return (<Document>
        <Page style={styles.page} size={[100, 400]} wrap
        >
            {console.log("Receipt Pdf LabTest=> Invoice 36 data pass to this Pdf: ",invoice)}
           
                <View
                style={{
                    width:"100%",
                    marginLeft:8,
                    marginTop:10
                }}
                >
                    <Text
                    style={{
                        fontSize:6
                    }}>FSLS</Text>
                </View>
                <View style={{
                    marginTop:2
                }}>
                        <Text style={{ textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 6 }}>FSLS ID : {invoice.invoice_id}</Text>
                 </View>
               
               <View>
                   <InvoiceItemsTable 
                   invoice={invoice}
                   />
               </View>
               <View style={{ width: '100%', marginTop:5 }}>
            <View style={{
                flexDirection:'column',
            }}>
       
<Text style={{

    textAlign: 'center',
    padding: 2,
    width:"100%",
    borderTopColor:'black',
    borderTopWidth:0.5,
    fontSize: 5,
    color: 'black'
}}>
    {"User : "}<Text style={{
        fontFamily: 'Helvetica-Bold'
    }}>{invoice.User_Name}</Text>
</Text>
<Text style={{

textAlign: 'center',
padding: 2,
width:"100%",
borderTopColor:'black',
borderTopWidth:0.5,
borderBottomWidth:0.5,
fontSize: 5,
color: 'black'
}}>
{"Date Book : "}<Text style={{
    fontFamily: 'Helvetica-Bold'
}}>{invoice.invoice_date}</Text>{" at "}<Text style={{
    fontFamily: 'Helvetica-Bold'
}}>{invoice.invoice_time}</Text>
</Text>

</View>
</View>
           
        </Page>
    </Document>
    )
}

export default Invoice