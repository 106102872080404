import React, {useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";
import ViewWeekIcon from '@material-ui/icons/ViewWeek';

import ReactLoading from 'react-loading';


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import { Robo } from '../Invoice/firebase';

import Grid from "@material-ui/core/Grid";
import DvrIcon from '@material-ui/icons/Dvr'


import Slide from "@material-ui/core/Slide";

import MaterialTable from "material-table";
import { set } from "date-fns";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    position: "absolute",
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    marginLeft: "13em",
    marginTop: "5em",
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  }
}));
const formatAMPM=(date)=>{
  var hours = date.getHours();
  var minutes = date.getMinutes()
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes
  return strTime;
}
const downloadTxtFile = (downloadObj) => {
  const element = document.createElement("a");
  const file = new Blob(["   Sample ID   : "+downloadObj.sample_id+"\n   Name   : "+downloadObj.name+"\n   Test Title   : "+downloadObj.test_title+"\n   Date   : "+downloadObj.date+" "+downloadObj.time+"\n   Sample Type   : "+downloadObj.sample_type+"\n  \n  \n  "], {type: 'text/plain'});
  element.href = URL.createObjectURL(file);
  element.download = downloadObj.sample_id+"-"+downloadObj.name+"-"+downloadObj.test_title;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}
export default function Phelebotomy({invoice_no,BoxClose}) {
  const classes = useStyles();
  const [loading,setLoading]=React.useState(false)
  const [patient_id,setPatientID]=React.useState(0)
  const [patient_name,setPatientName]=React.useState("")
  const [mobile_no,setMobileNo]=React.useState("")
  const [gender,setGender]=React.useState("")
  const [Samples,setSamples]=React.useState([])
  const [selectedSamples,setSelectedSamples]=React.useState([])
  const [selectedRobo7,setSelectedRobo7]=React.useState([])
 
useEffect(()=>{
  if(localStorage.getItem('user_id')==null || localStorage.getItem('user_id')==undefined){
    window.location.href="https://invoicing.fslabs.com.pk/login"
  }
  console.log(invoice_no)
  var obj={
    invoice_no:invoice_no
  }
  fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/Web_PhlebotomyInvoiceTestsCall", {
      method:"POST",
      body:JSON.stringify(obj)
  }).then(res=>res.json()).then((response)=>{
    console.log("Phelebotmy useEffect 99 Api Response Phelebotomy samples :",response)
      setPatientID(response[0].patient_id)
      setPatientName(response[0].patient_name)
      setMobileNo(response[0].mobile_no)
      setGender(response[0].gender)
      var arr=JSON.parse(response[0].ArrayAuxID)
      var temp_samples=[]
      var counter=0
      arr.map((item)=>{
          temp_samples.push({
            ArrayAuxID:JSON.parse(response[0].ArrayAuxID)[counter],
              ArrayTestID:JSON.parse(response[0].ArrayTestID)[counter],
              ArrayTestCode:JSON.parse(response[0].ArrayTestCode)[counter],
              ArrayTestName:JSON.parse(response[0].ArrayTestName)[counter],
              ArrayTestUpdate:JSON.parse(response[0].ArrayTestUpdate)[counter]
          })
          counter=counter+1
      })
      setSamples(temp_samples)

      // BC ROBO 7
      fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/BC_ROBO_SampleIDsCallingUp/" + invoice_no).then((res) => res.json()).then((sample) => {
  
        var sample_ids=JSON.parse(sample[0].Arr_Sample_ID)
         console.log(sample)
        var BcRobo7=[]
        var counter=0
        sample_ids.map((item)=>{
          var test_title=JSON.parse(sample[0].Test_Codes)[counter]
          var sample_type=JSON.parse(sample[0].Arr_Type_Name)[counter]
          var bottleColor=JSON.parse(sample[0].Arr_RoboCode)[counter]==1 ? "Purple Top" : JSON.parse(sample[0].Arr_RoboCode)[counter]==2 ? 'Yellow Top' : JSON.parse(sample[0].Arr_RoboCode)[counter]==3 ? 'Blue Top' : JSON.parse(sample[0].Arr_RoboCode)[counter]==4 ? 'Green Top' :JSON.parse(sample[0].Arr_RoboCode)[counter]==5 ? 'Grey Top' : JSON.parse(sample[0].Arr_RoboCode)[counter]==6 ? 'Yellow Top': JSON.parse(sample[0].Arr_RoboCode)[counter]==0 ? 'Manual':''
          var desc=JSON.parse(sample[0].Arr_Type_Descp)[counter]+""
          var obj={
            id:item,
            code:JSON.parse(sample[0].Test_Codes)[counter],
            desc:JSON.parse(sample[0].Arr_Type_Descp)[counter],
            bottleColor:bottleColor,
            // for BCrobo 7 29-DEC-22 (1/2)
            refrence_no:sample[0].ref_no!=""?sample[0].ref_no:"Nil",
            // MPL or FSLS name hai ye MPL ID: es me MPL dynamic aa rha
            idTag:sample[0].id_tag,
            // for BCrobo 7 17-JAN-23 (1/2)
            sample_type:sample_type,

            download: <Button variant="contained" style={{backgroundColor:'white', color:'black'}} key={item.invoiceNo} onClick={()=>{
              var downloadObj={
                sample_id:item,
                age_gender:response[0].gender,
                test_title:test_title,
                date:new Date().ddmmyyy(),
                mpl_id:invoice_no,
                name:(desc.includes("Urine") || desc.includes("Semen") || desc.includes("Stool")) ? "" :response[0].patient_name,
                patient_id:response[0].patient_id,
                time:formatAMPM(new Date()),
                sample_type:sample_type
    
              }
              downloadTxtFile(downloadObj)
            }} >Download</Button>,
            barcode:     <Button variant="contained" style={{backgroundColor:'white', color:'black'}} key={item.invoiceNo} onClick={()=>{
              var obj1={
                sample_id:item,
                age_gender:response[0].gender,
                test_title:test_title,
                date:new Date().ddmmyyy(),
                mpl_id:invoice_no,
                name:(desc.includes("Urine") || desc.includes("Semen") || desc.includes("Stool")) ? "" :response[0].patient_name,
                patient_id:response[0].patient_id,
                time:formatAMPM(new Date()),
                sample_type:sample_type,
                // for Barcode 29-DEC-22 (1/2)
                refrence_no:sample[0].ref_no!=""?"REF NO : "+sample[0].ref_no+" | ":"REF NO : "+"Nil   "+" | ",
              // MPL or FSLS name hai ye MPL ID: es me MPL dynamic aa rha
                idTag:sample[0].id_tag,
  }
              console.log(item)
              console.log(obj1)
              console.log(response[0])
              console.log(sample[0])

              var url="http://localhost:1000/GETBarcode"
              fetch(url,{
                method:"POST",
                body:JSON.stringify(obj1),
                headers:{
                  'Content-Type':"application/json"
                }
              }).then(()=>{
                alert("Print Request Sent")
              })
            }}>BARCODE</Button>
          }
          console.log(obj)
         BcRobo7.push(obj)
          counter=counter+1
        })
        console.log("SelectedRobo7 array 186",BcRobo7)
        setSelectedRobo7(BcRobo7)
    
    
    })
    
  })
 
},[])

  
  return (
    <div className={classes.root}>
        {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
     <div>
            <Grid container >
            <Grid item xs={2} sm={2} md={2} lg={1}></Grid>          
      <Grid item xs={2} sm={2} md={2} lg={2}>
      <TextField required id="" label="Invoice No" value={invoice_no} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Patient ID" value={patient_id} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Patient Name" value={patient_name}/>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Mobile No" value={mobile_no}/>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <TextField required id="" label="Gender" value={gender} />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={1}></Grid>          
      </Grid>
      
 <Grid container style={{
     justifyContent:"space-evenly"
 }}>
  {localStorage.getItem("org_branch_id")==1?<></>:
 <MaterialTable
 fullWidth={true}
      title="Phelebotomy Samples"
      style={{
          width:"40em"
      }}

      columns={[
       { title: 'ID', field: 'ArrayAuxID' },
        { title: 'Code', field: 'ArrayTestCode' },
        { title: 'Name', field: 'ArrayTestName' }
      ]}
      onSelectionChange={(rows) => {
        setSelectedSamples(rows)
      }}
      data={Samples}        
      options={{
        selection: true
      }}
    />
}
    <MaterialTable
 fullWidth={true}
      title={localStorage.getItem("org_branch_id")==1?"Barcode":"BC ROBO 7"}
      style={{
          width:"60em"
      }}
data={selectedRobo7}
      columns={[
       { title: 'Sample ID', field: 'id' },
       { title: 'Sample Name', field: 'code' },
       { title: 'Sample Description', field: 'desc' },
       { title: 'Barcode', field: 'barcode' },
       { title: 'Download Bottle Sticker', field: 'download' },
       { title: 'Bottle Color', field: 'bottleColor' ,editable: 'never', render: rowData => {
        return <p style={{ backgroundColor: rowData.bottleColor=="Manual" ? "black" : rowData.bottleColor.split('Top')[0],  fontWeight: "bold", textTransform:'uppercase', textAlign:"center", color:'white' }}>{rowData.bottleColor}</p>   
      }
    } 
       
      ]}
      actions={[
        {
          tooltip: 'Barcodes',
          icon: ()=><ViewWeekIcon fontSize={"large"} style={{fontSize:"2em"}} />,
          onClick: async(evt, sample) => {
           sample.map((row)=>{
            var obj1={
              sample_id:row.id,
              age_gender:gender,
              test_title:row.code,
              date:new Date().ddmmyyy(),
              mpl_id:invoice_no,
              name:(row.desc.includes("Urine") || row.desc.includes("Semen") || row.desc.includes("Stool")) ? "" : patient_name,
              patient_id:patient_id,
              time:formatAMPM(new Date()),
              // sample_type:row.bottleColor
                // for Barcode 17-JAN-23 Niche wali line comment ki hai aur sample_type new dala hai
              sample_type:row.sample_type,
              // sample_type:row.bottleColor,
                // for Barcode 29-DEC-22 (2/2)
                 refrence_no:row.refrence_no!=""? "REF NO : "+row.refrence_no+" | ":"REF NO : "+"Nil   "+" |",
                 // MPL or FSLS name hai ye MPL ID: es me MPL dynamic aa rha
                idTag:row.idTag,
            }
            console.log("298 barcode ",obj1)
            var url="http://localhost:1000/GETBarcode"
            fetch(url,{
              method:"POST",
              body:JSON.stringify(obj1),
              headers:{
                'Content-Type':"application/json"
              }
            }).then(()=>{
              alert("Print Request Sent")
            })

          })
        }
      }
        ,
        {
          tooltip: 'Request BC ROBO 7 for Tubes',
          icon: ()=><DvrIcon fontSize={"large"} style={{fontSize:"2em"}} />,
          onClick: async(evt, sample) => {
          Robo.database().ref("robo").once('value', async (data)=>{
            //Robo me jo Quene_No and Unique_No hai wo get krny k lye
             var Quene_No=data.toJSON().Quene_No
              var UniqueNo=data.toJSON().UniqueNo
          
        var date=new Date()
              var packing=await sample.map((item)=>{
                  return {
                    Terminal_No:"0005",
                    Terminal_HOST:"0001",
                    UniqueNo:UniqueNo+"",
                    Quene_No:Quene_No+"",
                    Sample_Volume:"1.0",
                    Stocker_Code:item.bottleColor.toLowerCase().includes('purple') ? '01' : item.bottleColor.toLowerCase().includes('yellow') ? '02' : item.bottleColor.toLowerCase().includes('blue') ? '03' : item.bottleColor.toLowerCase().includes('green') ? '04' : item.bottleColor.toLowerCase().includes('grey') ? '05' : '99',
                    RFID:"",
                    Priority:"0",
                    Department:"ROBO",
                    Order_Name:item.code,
                    Invoice_No:invoice_no,
                    Sample_ID:item.id+"",
                    Patient_ID:patient_id,
                    // Patient_Name:(item.desc.includes("Urine")|| item.desc.includes("Semen") || item.desc.includes("Stool")) ? "" : patient_name,
                    Patient_Name: (item.sample_type.includes('Urine') || item.sample_type.includes('Semen') || item.sample_type.includes('Stool')) ? "" : patient_name,
                   
                    Barcode:item.id+"", 
                    Logo:"MPL",
                    // for BCrobo 7 29-DEC-22 (2/2)
                    RTitle:"RNO",
                    refrence_no:item.refrence_no,
                    idTag:item.idTag,
                    // for BCrobo 7 17-Jan-22 (1/1)
                    // sample_type:item.sample_type,

                   }
                  
          })
          console.log("333 BCROBO array",JSON.stringify(packing))
          Promise.all(packing).then((values)=>{

              fetch("http://localhost:1200/ProvideSampleBottles",{
                  method:"POST",
                  headers:{
                      "Content-Type":"application/json"
                  },
                  body:JSON.stringify(values)
              }).then((response)=>{
          var tempDate=date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()
                       
           Robo.database().ref("robo/Invoices/"+tempDate).once('value', async (data)=>{
               var Invoices=JSON.parse(data.toJSON())
               var the_rows=[]

               Invoices.map((item)=>{
               item=JSON.parse(item)
               if(invoice_no==item.invoiceNo){
                //yhan pe status update ho rha hai
                   item.status="Updated"
               }
               
               the_rows.push(JSON.stringify(item))
               })
               console.log(the_rows)
               Invoices=JSON.stringify(the_rows)
              Robo.database().ref("robo/Invoices/"+tempDate).set(Invoices)
              alert("You Request has been successfully sent!!")
            
        })
           })
          })
          })

        }
      }
      
      ]}

      options={{
        selection: true
      }}
    />
 </Grid>

 {localStorage.getItem("org_branch_id")==1?<></>:
 <Button
                variant="contained"
                color="primary"

                style={{
                  padding:"1.5em",
                  fontSize:"1.3em",
              backgroundColor:"#125892"

                }}
                className={classes.button}
                onClick={()=>{
                  var res = window.confirm('Do you want to Do Phelebotomy???')
                        if (res) {
                          if(selectedSamples.length==0){
                            alert("No Sample Selected!!")
                          }else{
                            var ArrayAuxID=[]
                            var ArrayTestCode=[]
                            var ArrayTestID=[]
                            var ArrayTestName=[]
                            var ArrayTestUpdate=[]
                          selectedSamples.map((item)=>{
                          ArrayAuxID.push(item.ArrayAuxID)
                          ArrayTestCode.push(item.ArrayTestCode)
                          ArrayTestID.push(item.ArrayTestID)
                          ArrayTestName.push(item.ArrayTestName)
                          ArrayTestUpdate.push(true)
        
                          })
        
                          var obj={
                            invoice_no:invoice_no,
                            user_id:localStorage.getItem('user_id'),
                            user_name:localStorage.getItem('name'),
                            ArrayAuxID: ArrayAuxID,
                            ArrayTestCode:ArrayTestCode,
                            ArrayTestID: ArrayTestID,
                            ArrayTestName:ArrayTestName,
                            ArrayTestUpdate:ArrayTestUpdate
                          }
                        }
                        } else {
                         
                          return
                        }
                        console.log("check 445"+JSON.stringify(obj))
                 

                  fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/Web_PhlebotomyUpdateCallsSample", 
                  {
                    method:"POST",
                    body:JSON.stringify(obj)
                  }
                  ).then(res=>res.json()).then((response)=>{
             
                alert("Phelebotomy Done!!")
               BoxClose()
                }).catch(error => {
                     console.error('Error:', error);
                   })

                  
                }}
              >
                Do Phelebotomy
              </Button>
}
</div>
     
}
</div>
  );
}
