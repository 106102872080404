import React, {useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";

import MenuItem from '@material-ui/core/MenuItem';

import Camera from 'react-html5-camera-photo';

import DateFnsUtils from '@date-io/date-fns';

import InputMask from 'react-input-mask';

import ReactLoading from 'react-loading';


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Grid from "@material-ui/core/Grid";

import InputLabel from '@material-ui/core/InputLabel';
import { RenderPageProps, Viewer } from '@react-pdf-viewer/core';

import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Select from '@material-ui/core/Select';
import Paper from "@material-ui/core/Paper";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import Slide from "@material-ui/core/Slide";

import Modal from "@material-ui/core/Modal";
import Checkbox from '@material-ui/core/Checkbox';

import PictureAsPdfSharpIcon from "@material-ui/icons/PictureAsPdfSharp";
import Switch from "@material-ui/core/Switch";

import MaterialTable from "material-table";

import Autocomplete from "@material-ui/lab/Autocomplete";

import FormControl from "@material-ui/core/FormControl";
import { set } from "date-fns";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    position: "absolute",
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    marginLeft: "13em",
    marginTop: "5em",
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  }
}));

export default function EditPatient({patient_id}) {
  const classes = useStyles();
  const [id,setID]=React.useState('')
  const [name,setName]=React.useState('')
  const [mob,setMob]=React.useState('')
  const [dataUri, setDataUri] = React.useState('');
  const [checkedTakePhoto, setCheckedTakePhoto] = React.useState(true);
  const [checkedUploadImage, setCheckedUploadImage] = React.useState(false);
  const [doctor, setDoctor]=React.useState(true)
  const [employee,setEmployee]=React.useState(false)
  const [firstName,setfirstName]=React.useState('')
  
  const [husband,setHusband]=React.useState('')
  const [lastName,setlastName]=React.useState('')
  const [fatherName,setfatherName]=React.useState('')
  const [mobile,setMobile]=React.useState('')
  const [CNIC,setCNIC]=React.useState('')
  
  const [country,setCountry]=React.useState('')
  const [email,setEmail]=React.useState('')
  const [phone,setPhone]=React.useState('')
  const [network,setNetwork]=React.useState('Select')
  const [address,setAddress]=React.useState('')
  const [passport,setPassport]=React.useState('')
  const [Fax,setFax]=React.useState('')
  const [Ref,setRef]=React.useState('')
  const [district,setDistrict]=React.useState('')
  const [Designation,setDesignation]=React.useState('')

  const [Date_Birth,setDate_Birth]=React.useState(new Date('01/01/1990'))
  const [gender,setGender]=React.useState('Select')
  const [employeeName,setEmployeeName]=React.useState('')  
  const [relation,setRelation]=React.useState('')
  const [title,setTitle]=React.useState('Select')
  const [city,setCity]=React.useState('')
  const [register,setRegister]=React.useState(false)

  const [ScanImage,setScanImage]=React.useState([])
  const [ScanPDF,setScanPDF]=React.useState([])
  const [ViewDoc,setViewDoc]=React.useState(false)
  const [Doc,setDoc]=React.useState('')
  const [DocType,setDocType]=React.useState('')
  const [panelSelection,setPanelSelection]=React.useState([])

  const [Attachment_Image,setAttachment_Image]=React.useState([])
 
  const [ArrayDMS_Scan_Image,setArrayDMS_Scan_Image]=React.useState([])
  const [ArrayDMS_Scan_Date,setArrayDMS_Scan_Date]=React.useState([])
  const [ArrayDMS_Scan_Title,setArrayDMS_Scan_Title]=React.useState([])
  const [ArrayDMS_PDF_Base64,setArrayDMS_PDF_Base64]=React.useState([])
  const [ArrayDMS_PDF_Date,setArrayDMS_PDF_Date]=React.useState([])
  const [ArrayDMS_PDF_Title,setArrayDMS_PDF_Title]=React.useState([])
  const [ArrayDMS_Attachment,setArrayDMS_Attachment]=React.useState({})
  const [counterImage,setCounterImage]=React.useState(-1)
  const [counterPDF,setCounterPDF]=React.useState(-1)
  const [Attachment_PDF,setAttachment_PDF]=React.useState([])
  const [refreshPDF,setRefreshPDF]=React.useState(true)
  const [refreshImage,setRefreshImage]=React.useState(false)

  const [loading,setLoading]=React.useState(true)

  const [panel, setPanel] = React.useState('')
  const [panelList,setPanelList]=React.useState([])

  const [EntitlementList,setEntitlementList]=React.useState([])
  const [EntitlementID,setEntitlementID]=React.useState("")
  const [EntitlementName,setEntitlementName]=React.useState("")
  const [EntitlementType,setEntitlementType]=React.useState("")
   // For Other than Human 10-jan-2023
   const [checkedOTH, setcheckedOTH] = React.useState(false);
   const [genderN, setGenderN] = React.useState('N/A')
   const [Date_BirthN, setDate_BirthN] = React.useState("00/00/0000")
  const handleChange = (event) => {
    setPanel(event.target.value)
  }
useEffect(()=>{
  if(localStorage.getItem('user_id')==null || localStorage.getItem('user_id')==undefined){
    window.location.href="https://invoicing.fslabs.com.pk/login"
  }




  if(patient_id!=undefined){
    var id=patient_id
    console.log(patient_id)
    fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/BookingInvoicePatientModifyCall/"+id).then((res)=>{
      return res.json()
  }).then((response)=>{
    setAddress(response[0].Address)
    setCNIC(response[0].CNIC)
    setCity(response[0].City)
    setCountry(response[0].Country)
    setDate_Birth(new Date(response[0].DOB))
    setDistrict(response[0].District)
    setGender(response[0].Gender)
    setMobile(response[0].Mobile)
    setPassport(response[0].Passport)
    setTitle(response[0].Salutation)
    setEmail(response[0].eMail)
    setfatherName(response[0].father_name)
    setfirstName(response[0].first_Name)
    setHusband(response[0].husband_name)
    setlastName(response[0].last_Name)
    setEntitlementID(response[0].Entitlement_ID)
    setEntitlementName(response[0].Entitlement_Name)
    setEntitlementType(response[0].Entitlement_Type)
    setRelation(response[0].Entitlement_Relation)
    setPanel(response[0].Panel_ID)
    
    setNetwork(response[0].Service==1 ? 'Telenor' : response[0].Service==2 ? 'Mobilink' : response[0].Service==3 ? 'Zong' : response[0].Service==4 ? 'Warid' : response[0].Service==5 ? 'Ufone' : 'Select')
   
    
    setArrayDMS_Attachment({
   
      ArrayDMS_PDF_Base64:JSON.parse(response[0].ArrayDMS_PDF_Base64),
      ArrayDMS_PDF_Title:JSON.parse(response[0].ArrayDMS_PDF_Title),
      ArrayDMS_PDF_Date:JSON.parse(response[0].ArrayDMS_PDF_Date),
      ArrayDMS_Scan_Date:JSON.parse(response[0].ArrayDMS_Scan_Date),
      ArrayDMS_Scan_Image:JSON.parse(response[0].ArrayDMS_Scan_Image),
      ArrayDMS_Scan_Title:JSON.parse(response[0].ArrayDMS_Scan_Title),
      AttachmentImageLength:JSON.parse(response[0].ArrayDMS_Scan_Title).length,
      AttachmentPDFLength:JSON.parse(response[0].ArrayDMS_PDF_Title).length

    })
    setLoading(false)
    fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetPanelsListAll").then((res)=>res.json()).then((response)=>{
      response.push({
        Panel_Code:"Normal",
        Panel_Title:"Normal Patient"
      })
      var obj={}
      response.map((item)=>{
        obj[item.Panel_Code]=item.Panel_Title
      })
      setPanelList(response)
      setPanelSelection(obj)
      })

    fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPatientsEntitlementGet").then((res)=>res.json()).then((response)=>{
 setEntitlementList(response)     
      })
 setTimeout(() => {
  PreviewDocs(JSON.parse(response[0].ArrayDMS_PDF_Base64),JSON.parse(response[0].ArrayDMS_PDF_Title),response[0].Salutation+" "+response[0].first_Name+" "+response[0].last_Name,JSON.parse(response[0].ArrayDMS_PDF_Date))

  PreviewImage(JSON.parse(response[0].ArrayDMS_Scan_Image),JSON.parse(response[0].ArrayDMS_Scan_Title),response[0].Salutation+" "+response[0].first_Name+" "+response[0].last_Name,JSON.parse(response[0].ArrayDMS_Scan_Date))

}, 1000);
  
  })
  }
  
},[])

const PreviewDocs= async (pdfbase64,titles,filename,dates)=>{
 if(titles!=undefined){
   var counter=0
  var Append_Docs=await titles.map((name)=>{
    console.log(filename)
    counter=counter+1
    var data={
      counter:counter,
      file:pdfbase64[counter-1],
      date:dates[counter-1],
      fileName:counter+" "+filename,
      Attachment:<Button
       variant="contained"
       color="secondary"
       style={{
         float:"left"
       }}
       key={"Report"+counter}
       className={classes.button}
       onClick={()=>{
         setDoc(pdfbase64[data.counter-1])
         setDocType('PDF')
        setTimeout(() => {
          setViewDoc(true)
        }, 1000);
        
       }}
       >{counter+" "+filename}</Button>,
       Remove: <Button
        variant="contained"
        color="primary"
        style={{
          float:"left"
        }}
        disabled={true}
        className={classes.button}
        onClick={()=>{
          
         var res= window.confirm('Are you Sure? You want to delete Attachment!!')
         if(res){
     
         }else{
           return
         }
       
         
        }}
        >Delete</Button>

    }
   

return data
  
   })
   Promise.all(Append_Docs).then((values)=>{
     console.log(values)
     setAttachment_PDF(values)
   })


 }
}


const PreviewImage= async (Imagebase64,titles,filename,dates)=>{
  if(titles!=undefined){
    var counter=0
   var Append_Docs=await titles.map((name)=>{
     console.log(filename)
     counter=counter+1
     var data={
       counter:counter,
       date:dates[counter-1],
       file:Imagebase64[counter-1],
       fileName:counter+" "+filename,
       Attachment:<Button
        variant="contained"
        color="secondary"
       
        style={{
          float:"left"
        }}
        key={"Report"+counter}
        className={classes.button}
        onClick={()=>{
         alert("Image can be visible at 4D Side.")
        }}
        >{counter+" "+filename}</Button>,
        Remove: <Button
         variant="contained"
         color="primary"
         style={{
           float:"left"
         }}
         disabled={true}
         className={classes.button}
         onClick={()=>{
           
          var res= window.confirm('Are you Sure? You want to delete Attachment!!')
          if(res){
      
          }else{
            return
          }
        
          
         }}
         >Delete</Button>
 
     }
    
 
 return data
   
    })
    Promise.all(Append_Docs).then((values)=>{
      console.log(values)
      setAttachment_Image(values)
      setRefreshImage(false)
  setTimeout(() => {
    setRefreshImage(true)
  }, 1000);
    })
 
 
  }
 }



  const handleChangeTake = (event) => {
    setCheckedTakePhoto(event.target.checked);
    setCheckedUploadImage(!event.target.checked)
  };
  const handleChangeUpload = (event) => {
    setCheckedUploadImage(event.target.checked);
    setCheckedTakePhoto(!event.target.checked)
  };

  const handleScanImage = async (event) => {
    const file = event.target.files[0]
    var fileInput = document.getElementById('ScanImage');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if(!allowedExtensions.exec(filePath)){
        alert('Please upload file having extensions .jpeg/.jpg/.png only.');
        fileInput.value = '';
        setScanImage('')
        return false;
    }else{
    const base64 = await convertBase64(file)
    setScanImage(base64)  

    // Upload Image

 
    var data={
      counter:Attachment_Image.length+1,
      file:base64,
      fileName:(Attachment_Image.length+1)+". "+title+" "+firstName+" "+lastName,
      date:new Date().toISOString(),
      Attachment:<Button
       variant="contained"
       color="secondary"
       style={{
         float:"left"
       }}
       className={classes.button}
       onClick={()=>{
         setDoc(base64)
         setDocType('Image')
        setTimeout(() => {
          setViewDoc(true)
        }, 1000);
        
       }}
       >{(Attachment_PDF.length+1)+". "+title+" "+firstName+" "+lastName}</Button>,
       Remove: <Button
        variant="contained"
        color="primary"
        style={{
          float:"left"
        }}
        className={classes.button}
        onClick={async()=>{
          
         var res= window.confirm('Are you Sure? You want to delete Attachment!!')
         if(res){
     
         }else{
           return
         }
         var temp=Attachment_Image
        temp.splice(data.counter-1,1)
        setAttachment_Image(temp)
        console.log(temp)
        var counter=0
        var recounter =await temp.map((item)=>{
        counter++;

        item.counter=counter
        item.fileName=counter+" "+title+" "+firstName+" "+lastName
       return item
       })
       Promise.all(recounter).then((values)=>{
        setAttachment_Image(values)
       })
       setRefreshImage(false)
       setTimeout(() => {
        setRefreshImage(true)
      }, 1000);
        }}
        >Delete</Button>

    }
    var temp=Attachment_Image
    temp.push(data)
    setAttachment_Image(temp)
  console.log(temp)
  setRefreshImage(false)
  setTimeout(() => {
    setRefreshImage(true)
  }, 1000);

  }

    }

  
  const handleScanPDF = async (event) => {
    const file = event.target.files[0]
    var fileInput = document.getElementById('ScanPDF');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.pdf)$/i;
    console.log(filePath)
    if(!allowedExtensions.exec(filePath)){
        alert('Please upload file having extensions .pdf only.');
        fileInput.value = '';
        setScanPDF([])
        return false;
    }else{
    const base64 = await convertBase64(file)
    setScanPDF(base64)  
    
    var data={
      counter:Attachment_PDF.length+1,
      file:base64,
      fileName:(Attachment_PDF.length+1)+". "+title+" "+firstName+" "+lastName,
      date:new Date().toISOString(),
      Attachment:<Button
       variant="contained"
       color="secondary"
       style={{
         float:"left"
       }}
       className={classes.button}
       onClick={()=>{
         setDoc(base64)
         setDocType('PDF')
        setTimeout(() => {
          setViewDoc(true)
        }, 1000);
        
       }}
       >{(Attachment_PDF.length+1)+". "+title+" "+firstName+" "+lastName}</Button>,
       Remove: <Button
        variant="contained"
        color="primary"
        style={{
          float:"left"
        }}
        className={classes.button}
        onClick={async()=>{
          
         var res= window.confirm('Are you Sure? You want to delete Attachment!!')
         if(res){
     
         }else{
           return
         }
         var temp=Attachment_PDF
        temp.splice(data.counter-1,1)
        setAttachment_PDF(temp)
        console.log(temp)
        var counter=0
        var recounter =await temp.map((item)=>{
        counter++;

        item.counter=counter
        item.fileName=counter+" "+title+" "+firstName+" "+lastName
       return item
       })
       Promise.all(recounter).then((values)=>{
        setAttachment_PDF(values)
       })
       setRefreshPDF(false)
       setTimeout(() => {
        setRefreshPDF(true)
      }, 1000);
        }}
        >Delete</Button>

    }
    var temp=Attachment_PDF
    temp.push(data)
  setAttachment_PDF(temp)
  console.log(temp)
  setRefreshPDF(false)
  setTimeout(() => {
    setRefreshPDF(true)
  }, 1000);

  }

  }
 const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
 
  function handleTakePhotoAnimationDone (dataUri) {
    console.log(dataUri);
    setDataUri(dataUri);
  }
   // For Other than Human 10-jan-2023
const handleChangeOTH = (event) => {
  setcheckedOTH(event.target.checked);
  // setCheckedUploadImage(!event.target.checked)
};
  
  return (
    <div className={classes.root}>
        {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
      <form noValidate autoComplete="off">
      
        <div>

        <br />
            <Grid container >
                <Grid item xs={2} sm={2} md={2} lg={2}>
        <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Title</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={title}
          onChange={(e)=>{
            setTitle(e.target.value)
          }}
        >
          <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
         
          <MenuItem value={"Mr."}>Mr.</MenuItem>
          <MenuItem value={"Mrs."}>Mrs.</MenuItem>
          <MenuItem value={"Ms."}>Ms.</MenuItem>
          <MenuItem value={"Miss"}>Miss</MenuItem>
          <MenuItem value={"Baby"}>Baby</MenuItem>
          <MenuItem value={"Master"}>Master</MenuItem>
          <MenuItem value={"Sir"}>Sir</MenuItem>
          <MenuItem value={"Madam"}>Madam</MenuItem>
          <MenuItem value={"F/O"}>F/O</MenuItem>
          <MenuItem value={"C/O"}>C/O</MenuItem>
          <MenuItem value={"B/O"}>B/O</MenuItem>
          <MenuItem value={"W/O"}>W/O</MenuItem>
          <MenuItem value={"D/O"}>D/O</MenuItem>
          <MenuItem value={"S/O"}>S/O</MenuItem>
          <MenuItem value={"H/O"}>H/O</MenuItem>
          <MenuItem value={"M/O"}>M/O</MenuItem>
          <MenuItem value={"Dr."}>Dr.</MenuItem>

          
        </Select>
      </FormControl>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
      <TextField required id="" 
                  value={firstName}
                  onChange={(e)=>{
                    setfirstName(e.target.value)
                  }}
                  label="First Name"
                  
                  />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
      <TextField required id="" 
                      value={lastName}
                      onChange={(e)=>{
                        setlastName(e.target.value)
                      }}
                  label="Last Name"
                  
                  />
                  
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} style={{marginLeft:10}}>
      
      <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Gender</InputLabel>
              {/* // For Other than Human 10-jan-2023 */}
        {checkedOTH?<Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={genderN}
                 
                >
                  <MenuItem disabled={true} value={"N/A"}>N/A</MenuItem>
 
                </Select>:
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={gender}
          onChange={(e)=>{
            setGender(e.target.value)
          }}
        >
        <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          <MenuItem value={"Male"}>Male</MenuItem>
          <MenuItem value={"Female"}>Female</MenuItem>
          <MenuItem value={"Other"}>Other</MenuItem>
          

        </Select>}
      </FormControl>
      
</Grid>
<Grid item xs={2} sm={2} md={2} lg={2} justify="center">
 {/* // For Other than Human 10-jan-2023 */}
{checkedOTH?<>
              <div>Date Of Birth <br/> N/A</div>
              </>:
<MuiPickersUtilsProvider utils={DateFnsUtils}>
 <KeyboardDatePicker
        variant="inline"
        inputVariant="outlined"
        label="Date of Birth"
        format="dd/MM/yyyy"
        value={Date_Birth}
        InputAdornmentProps={{ position: "start" }}
        onChange={date=> setDate_Birth(date)}
      />
      </MuiPickersUtilsProvider>}
      {/* <Grid item xs={3} sm={3} md={3} lg={3}  >
     <b>Take Photo</b>
      <Checkbox
        checked={checkedTakePhoto}
        onChange={handleChangeTake}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
       <b>Upload Image</b>
      <Checkbox
        checked={checkedUploadImage}
        onChange={handleChangeUpload}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />

          </Grid> */}
      {/* <Grid item xs={3} sm={3} md={3} lg={3} >
      <div style={{width:'10em', height:'10em'}}>
      { checkedTakePhoto ?
        (dataUri)
          ? <img src={dataUri}
          width={250} 
          />
          : 
          <Camera  onTakePhotoAnimationDone = {handleTakePhotoAnimationDone}
          isFullscreen={false}
          />
          :
          <div style={{display:'flex', right:0, position:'absolute'}}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
                 <input type="file" variant="contained"
            color="primary"
            className={classes.button} 
            name="my_file" 
            aria-label="Attach PDF"
          />
          </Button>
          </div>

      }
    </div>
      </Grid> */}
      
      </Grid>
   
      <br />
      <br />
      

{/* <Grid item xs={2} sm={2} md={2} lg={2} style={{marginLeft:5}}>
    

        
              
<b>Attach PDF</b>
                 <input type="file" variant="contained"
            color="primary" 
            name="my_file[]" 
          
            multiple />
      
          
    </Grid> */}
    <Grid item xs={2} sm={2} md={2} lg={2}>
    {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Scan Documents
          </Button> */}
          
          
    </Grid>
          </Grid>
          <br />
          <Grid container>
      <Grid item xs={2} sm={2} md={2} lg={2}>
      <TextField  id="" 
          value={fatherName}
          onChange={(e)=>{
            setfatherName(e.target.value)
          }}
                  label="Father Name" 
                  />
</Grid>
{
  title=='Mrs.'
  ?
  <Grid item xs={2} sm={2} md={2} lg={2}>
      <TextField  id="" 
          value={husband}
          onChange={(e)=>{
            if(e.target.value.trim()!=""){
              var value=e.target.value
              var res=value.replace(/^./, value[0].toUpperCase()); 
         
                       setHusband(res)
             }else{
              setHusband("")
             }
          }}
                  label="Husband Name" 
                  />
</Grid>
:null
}
<Grid item xs={2} sm={2} md={2} lg={2}>
{
               localStorage.getItem('name')=='mpl-battagram' 
               ?
               <TextField  id="" 
            value={mobile}
            type="number"
            onChange={(e)=>{
              setMobile(e.target.value)
            }}
                  label="Mobile Number" 
                  />
                  :
               <InputMask mask="9999-9999999" 
               value={mobile} 

               onChange={(e)=>{
                 var string=e.target.value.toString()
                 var res=string.replaceAll( "_", '');
                res=res.replaceAll( "-", '');
                   setMobile(res)
                 
               }}>
     {(inputProps) => <TextField
      {...inputProps} 
      placeholder="03XX-XXXXXXX (Mobile No)"
      type="tel" />}
   </InputMask>
             }
</Grid>
<Grid item xs={2} sm={2} md={2} lg={6}>
    {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Scan Documents
          </Button> */}
          <Checkbox style={{marginLeft:0}}
        checked={checkedOTH}
        onChange={handleChangeOTH}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        
      />
         <b style={{marginTop:0,marginRight:0}}>Other Than Human</b>
          
    </Grid>


          </Grid>
          <br />
          <Grid container>
          <Grid item xs={2} sm={2} md={2} lg={2}>
          <TextField id="" 
            value={email}
            onChange={(e)=>{
              setEmail(e.target.value)
            }}
                  label="Email" 
                  />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
          <TextField  id="" 
            value={passport}
            onChange={(e)=>{
              setPassport(e.target.value)
            }}
                  label="Passport No" 
                  />
              </Grid>
              <br />
          
              <Grid item xs={2} sm={2} md={2} lg={2}>
              {
                  localStorage.getItem('name')=='mpl-battagram'?
                  <TextField  id="" 
            value={CNIC}
          type="number"
            onChange={(e)=>{
              setCNIC(e.target.value)
            }}
                  label="CNIC" 
                  />
                  :

                  
                  <InputMask mask="99999-9999999-9" 
              value={CNIC} 
              onChange={(e)=>{
                var string=e.target.value.toString()
                var res=string.replaceAll( "_", '');
                res=res.replaceAll( "-", '');
             
                setCNIC(res)
              }}>
    {(inputProps) => <TextField
     {...inputProps} 
     placeholder="XXXXX-XXXXXXX-X (CNIC)"
     type="tel" />}
  </InputMask>
                }
              </Grid>
              <Grid item xs={2} sm={2} md lg={2} style={{ marginLeft: 10 }} >
              <FormControl variant="filled" className={classes.formControl} style={{ width: "-webkit-fill-available" }} >
                <InputLabel id="demo-simple-select-filled-label">Mobile Network</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={network}
                  onChange={(e) => {
                    setNetwork(e.target.value)
                  }}
                >
                  <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
                  <MenuItem value={"Ufone"}>Ufone</MenuItem>
                  <MenuItem value={"Zong"}>Zong</MenuItem>
                  <MenuItem value={"Mobilink"}>Mobilink</MenuItem>
                  <MenuItem value={"Telenor"}>Telenor</MenuItem>
                  <MenuItem value={"Warid"}>Warid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
              </Grid>
              <br />
              <Grid container>
            <Grid item xs={4} sm={4} md={4} lg={4}>
            <TextField required id=""
                  label="Address"
                  value={address}
                  onChange={(e)=>{
                    setAddress(e.target.value)
                  }}
                 style={{width:'90%'}}
                  multiline={3} 
                  />       
            
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} >
            <TextField required id="" 
              value={district}
              style={{width:'95%'}}
              onChange={(e)=>{
                setDistrict(e.target.value)
              }}
                  label="District"
                  />       
            
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} >
            <TextField required id="" 
              value={city}
              style={{width:'95%'}}
              onChange={(e)=>{
                setCity(e.target.value)
              }}
                  label="City"
                  />       
            
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} >
            <TextField  id="" 
              value={country}
              style={{
                width:'95%'
              }}
              onChange={(e)=>{
                setCountry(e.target.value)
              }}
                  label="Country"
                  />       
            
            </Grid>
            {/* <Grid item xs={4} sm={4} md={4} lg={4}>
                <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Tag with Panel</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={panel}
          onChange={(e)=>{
            setPanel(e.target.value)
          }}
        >
        <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          <MenuItem value={"Future Scientific"}>Future Scientific</MenuItem>
          <MenuItem value={"Metropole"}>Metropole</MenuItem>
        </Select>
      </FormControl>
                    </Grid> */}
            </Grid>
            <Grid container>
           <Grid item xs={4} sm={4} md={4} lg={4}>
         {
           panel!='' ?
       <>
           <TextField required id="" 
           value={panelSelection[panel]}
         fullWidth={true}
               /> 
               <Button
               variant="contained"
               color="primary"
               className={classes.button}
               onClick={()=>{
               setPanel('')
               }}
               >Change Panel</Button>
            </>
           :
           <Autocomplete
           id="country-select"
           options={panelList}
           classes={{
             option: classes.option
           }}
           autoHighlight
            onChange={(event, newValue) => {
             if(newValue!=null){

               handleChange({
                 target:{
                   value:newValue.Panel_Code
                 }
               })
               
             }else{
               handleChange({
                 target:{
                   value:"Normal"
                 }
               })
             }
           }}
           getOptionLabel={(option) => option.Panel_Title}
           style={{
             width:"20em"
           }}
           renderOption={(option) => <React.Fragment>{option.Panel_Title}</React.Fragment>}
           
           renderInput={(params) => (
             <TextField
               {...params}
               label="Choose a Panel"
               variant="outlined"
               style={{
                 width:"20em"
               }}
               inputProps={{
                 ...params.inputProps,
                 autoComplete: "new-password" // disable autocomplete and autofill
               }}
             />
           )}
         />
         }
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      
                         {
                          EntitlementID!='' ?
                      <>
                          <TextField required id="" 
                          value={EntitlementName}
                        fullWidth={true}
                              /> 
                              <Button
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              onClick={()=>{
                              setEntitlementName('')
                              setEntitlementID('')
                              setEntitlementType('')
                              setRelation('')
                              }}
                              >Change Entitlement</Button>
                           </>
                          :
          <Autocomplete
            id="country-select"
            options={EntitlementList}
            classes={{
              option: classes.option
            }}
            autoHighlight
             onChange={(event, newValue) => {
               if(newValue!=null){
                 setEntitlementName(newValue.ArrEntitlement_Name)
                 setEntitlementID(newValue.ArrEntitlement_ID)
                 setEntitlementType(newValue.ArrEntitlement_Type)
               
               }else{
                setEntitlementName("")
                setEntitlementID("")
                setEntitlementType("")
               }

             
            }}
            getOptionLabel={(option) => option.ArrEntitlement_Name}
            style={{
              width:"20em"
            }}
            renderOption={(option) => <React.Fragment>{option.ArrEntitlement_Name}</React.Fragment>}
            
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose Entitlement"
                variant="outlined"
                style={{
                  width:"20em"
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
              }
                    </Grid>   
                       
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                    <TextField required id="" 
                  label="Relation"
                  value={relation}
                  onChange={(e)=>{
                    setRelation(e.target.value)
                  }}
                  />     
                        </Grid>
      
           </Grid>
            <Grid container>
            <Button
  variant="contained"
  color="primary"
  className={classes.button}
>
  <b>Patient Docs as PDF</b>
       <input type="file" variant="contained"
      
  color="primary"
  onChange={(e)=>{handleScanPDF(e)}}
  id="ScanPDF" name="pdf" accept="application/pdf"
  className={classes.button} 
  aria-label="Attach PDF"
/>
</Button>
        <Button
  variant="contained"
  color="primary"
  className={classes.button}
>
  <b>Patient Docs as Image</b>
       <input type="file" variant="contained"
  color="primary"
  accept="image/*"
  onChange={(e)=>{handleScanImage(e)}}
  id="ScanImage" name="img" 
  className={classes.button} 
  
  aria-label="Attach PDF"
/>
</Button>




         </Grid>
        
          <Grid container style={{
            float:'left',
            flex:1,
            width:'100%',
            flexDirection:"row"
          }}>
         
           
          <MaterialTable
          style={
            {
              padding:"1em",
              width:"50em"
            }
          }
          columns={[
            { field: "Attachment", title: "Attachment" },
            { field: "Remove", title:"Remove" }
              ]}
          data={ refreshPDF ? Attachment_PDF : []}
          title={"Attachment as PDF"}
        />
        <MaterialTable
         style={
          {
            padding:"1em",
            width:"50em"
          }
        }
          columns={[
            { field: "Attachment", title: "Attachment" },
            { field: "Remove", title:"Remove" }
              ]}
          data={ refreshImage ? Attachment_Image : []}
          title={"Attachment as Image"}
        />
     
                
              
             </Grid>
            <br />
            <Grid container>
               
                    {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                    <TextField required id="" 
                    value={Ref}
                    onChange={(e)=>{
                      setRef(e.target.value)
                    }}
                  label="Ref No#"
                  />       
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                    <TextField required id="" 
                  label="Designation"
                  value={Designation}
                  onChange={(e)=>{
                    setDesignation(e.target.value)
                  }}
                  />       
                    </Grid> */}
                </Grid>
                <Grid container>
                <Button
                variant="contained"
                color="primary"
                disabled={register}
                className={classes.button}
                onClick={()=>{
                  setRegister(true)
                  
          // Image

          var Arr_D_TiTLE=[], Arr_D_DATE=[], Arr_D_ImaGE=[], Arr_BLOB_DatE=[],Arr_BLOB_TiTLE=[], Arr_BLOB_BLOB=[]
          Attachment_Image.map((item)=>{
            Arr_D_TiTLE.push(item.fileName)
            Arr_D_DATE.push(item.date)
            Arr_D_ImaGE.push(item.file)
          })
          Attachment_PDF.map((item)=>{
            Arr_BLOB_TiTLE.push(item.fileName)
            Arr_BLOB_DatE.push(item.date)
            Arr_BLOB_BLOB.push(item.file)
          })
        console.log(Arr_D_TiTLE)
        console.log(Arr_BLOB_TiTLE)
                  if(title=='Select'){
                    alert('Invalid Title')
                    
                  setRegister(false)
                    return
                  } 
                  if(firstName.trim()==''){
                    alert('Invalid First Name')
                    
                  setRegister(false)
                    return
                  }           
                       
                    // For Other than Human 10-jan-2023
if(!checkedOTH){
                  if(gender=='Select'){
                    alert('Kindly Select Gender')
                    
                  setRegister(false)
                    return
                  } }     
                   // For Other than Human 10-jan-2023

                if(!checkedOTH){
                  if(Date_Birth=='Invalid Date'){
                    alert('Invalid Date of Birth')
                    
                  setRegister(false)
                    return
                  } 

                  if(Date_Birth.getFullYear()=='1900'){
                    alert('Invalid Date of Birth')
                    
                  setRegister(false)
                    return
                  } }
                 
                  var num=mobile+""
                  var cnic_=CNIC+""
                  if(num.trim=='' || num.length!=11){
                    alert('Invalid Mobile Number')
                    
                  setRegister(false)
                    return
                  }
   
                console.log(Date_Birth)
                var dd = Date_Birth.getDate();
                            var mm = Date_Birth.getMonth() + 1; //January is 0!
                
                            var yyyy = Date_Birth.getFullYear();
                            if (dd < 10) {
                                dd = '0' + dd;
                            }
                            if (mm < 10) {
                                mm = '0' + mm;
                            }
                var birth_date = dd + '/' + mm + '/' + yyyy;
                var obj={
                  patient_id:patient_id,
                  user_name:localStorage.getItem('name'),
                  first_name:firstName,
                  last_name:lastName,
                  email:email,
                  birth_date:checkedOTH?Date_BirthN:birth_date,
                  network:network,
                  gender:checkedOTH?genderN:gender,
                  to_address:title,
                  M_Status:"Single",
                  mobile_number:mobile,
                  cnic:CNIC,
                  passport:passport,
                  address:address,
                  district:district,
                  city:city,
                  branch_id:localStorage.getItem('branch_id'),
                  father_name:fatherName,
                  husband_name:husband,
                  country:country,
                  Entitlement_ID:EntitlementID,
                  Entitlement_Name:EntitlementName,
                  Entitlement_Type:EntitlementType,
                  Entitlement_Relation:relation,
                  Panel_ID:panel=="Normal" ? "": panel,
                  Arr_D_TiTLE:Arr_D_TiTLE, 
                  Arr_D_DATE:Arr_D_DATE, 
                  Arr_D_ImaGE:Arr_D_ImaGE, 
                  Arr_BLOB_DatE:Arr_BLOB_DatE,
                  Arr_BLOB_TiTLE:Arr_BLOB_TiTLE, 
                  Arr_BLOB_BLOB:Arr_BLOB_BLOB
         
                }
                console.log(obj)
             var vSearchStr=JSON.stringify(obj) 

                       fetch('https://eviewer.fslabs.com.pk:5443/4DACTION/BookingInvoiceModifyUpPatient',{
                 method:"POST",
                 body:vSearchStr
               }).then((response) => {
                          return response.text();
                       }).then((res)=>{
                         console.log(res) 
                        if(res=="Successful"){
                        alert('Patient Updated!!')
                        setRegister(false)
                        }
                        else if(res=="Failure"){

                        alert('Sorry , Update Failed!!')
                      
                        setRegister(false)
                          return
                      
                         }
                        
                      })
                  
                }}
              >
                Update
              </Button>
                  </Grid>
{/* Comment the Entitlement on 26 Nov 2020 */}
                {/* <Grid container>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                       <h2> Entitlement</h2> 
                    <b>Doctor</b>
      <Checkbox
      checked={doctor}
      onChange={(e)=>{
          setDoctor(e.target.checked)
        setEmployee(!e.target.checked)
        }}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
       <b>Employee</b>
      <Checkbox
        inputProps={{ 'aria-label': 'primary checkbox' }}
        checked={employee}
        onChange={(e)=>{

            setDoctor(!e.target.checked)
            setEmployee(e.target.checked)
        }}
      />

                    </Grid>
                    </Grid> */}
                    {/* <Grid container>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                    <TextField required id="" 
                  label="Employee's Name"
                  value={employeeName}
                  onChange={(e)=>{
                    setEmployeeName(e.target.value)
                  }}
                  />     
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                    <TextField required id="" 
                  label="Relation"
                  value={relation}
                  onChange={(e)=>{
                    setRelation(e.target.value)
                  }}
                  />     
                        </Grid>
                        </Grid> */}
                        

          {/* <TextField
            id="date"
            label="Date"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            required
            id="outlined-required"
            label="Panel (if related)"
            variant="outlined"
            style={{ width: "25em" }}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Scan Documents
          </Button> */}
            <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}

                  maxWidth={'xl'}
                  fullWidth={true}
                  open={ViewDoc}
                  TransitionComponent={Transition}
                  keepMounted
                  
                  onClose={() => {
                      setViewDoc(false)
                 
                  }}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>Patient Document</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    
                    {ViewDoc && DocType=='PDF' ?
                     <center> 
                   
                   <object data={Doc.includes('data:application/pdf;base64,') ? Doc : 'data:application/pdf;base64,'+Doc} width="500" height="375" 
 type="application/pdf" />
              
              </center>:
              ViewDoc && DocType=="Image" ?
              <img src={Doc} alt="Scan Image" width="250px" height="300px" style={{
                margin:"auto"
              }}/>
              :
              null}
                 
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => {
                          setViewDoc(false)
                       
                      }}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
        

</div>
</form>
}
</div>
  );
}
