import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import    InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTitle from './InvoiceTitle'


const InvoiceTableRow =  ({items, invoice}) =>  {
    var counter=0
    const rows =items.map( (item,index) => 
        
        {
        counter++

        return (
            <View>
        {/* { counter%11==0
        ?
             <View style={ {
                flexDirection: 'row',
                alignItems: 'center',
                width:"100%",
                height: item.reporting_date.includes("2022") ? "140em" : "80em"
                }} key={item.test_title} >
                    </View>
                    :null
        } */}
        {/* {
            counter%11==0 ?
            <>
            <InvoiceTableHeader  />
            </>
            : null
        } */}
        <View style={ {
        flexDirection: 'row',
        borderBottomColor: 'BLACK',
        borderBottomWidth: 0.5,
        borderTopWidth: counter%11 ? 0.5 :0,
        alignItems: 'center',
        width:"100%",
        fontSize:7,
        fontStyle: 'bold'
       
        }} key={item.test_title} >
             <Text style={{ 

        width: '5%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        height:'100%',
        padding:5,  
        fontSize:7,
        }}>{(index+1)}</Text>
         {/* <Text style={{ 
                 
        width: '37%',
        fontFamily: 'Helvetica-Bold',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        fontSize:6,
        height:'100%',
        padding:5
        
        
        }}>{item.test_title}</Text> */}
                        <View style={{

width: '37%',
// fontFamily: 'Helvetica-Bold',
// textAlign: 'left',
borderLeftColor: 'black',
borderLeftWidth: 0.5,
// fontSize: 6,
height: '100%',
padding: 5


}}>
                <Text style={{

                    // width: '100%',
                    fontFamily: 'Helvetica-Bold',
                    textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 0.5,
                    fontSize: 6,
                    // height: '100%',
                    // padding: 5


                }}>{item.test_title}</Text>
                  <Text style={{

// width: '25%',
// fontFamily: 'Helvetica-Bold',
textAlign: 'left',
// borderLeftColor: 'black',
// borderLeftWidth: 0.5,
fontSize: 6,
// height: '100%',
// padding: 5


}}>{item.tests_line_details}</Text>
</View>
         {/* <Text style={{ 
                 
        width: '19%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        
        fontSize:6,
        padding: 5,
        height:'100%'
        
        
        }}>{item.reporting_date}</Text> */}
        {/* //  25-apr-2024 to show reporting time in invoice for call a group in lab-test aalso agg group:true */}

{item.group ?
         <Text style={{ 
                 
        width: '19%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        
        fontSize:6,
        padding: 5,
        height:'100%',
        // marginTop:20
        
        
        }}>
           {'\n'}{'\n'}
            {

        item.reporting_date}</Text>:
        <Text style={{ 
                 
            width: '19%',
            textAlign: 'left',
            borderLeftColor: 'black',
            borderLeftWidth: 0.5,
            
            fontSize:6,
            padding: 5,
            height:'100%',
            // marginTop:20
            
            
            }}>
              
                {
    
            item.reporting_date}</Text>
    }
         <Text style={{ 
                 
        width: '13%',
        textAlign: 'right',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        
        fontSize:6.5,
        height:'100%',
        padding: 5,
        
        }}>{item.fee+" Rs"}</Text>
         <Text style={{ 
                 
        width: '13%',
        textAlign: 'right',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        height:'100%',
        
        fontSize:6.5,
        padding: 5,
        
        
        }}>{item.disc+" Rs"}</Text>
            <Text style={{ 
                 
        width: '13%',
        height:'100%',
        textAlign: 'right',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        fontSize:6.5,
        borderRightWidth: 0.5,
        textOverflow: 'auto',
        padding: 5
        
        }}>{item.net_value+" Rs"}</Text>
     
     </View>
     </View>
    )
        })
    

        return (<View>{rows}</View>)
    
};
  
  export default InvoiceTableRow