import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import HelpIcon from '@material-ui/icons/Help';
import { isMobile, ConsoleView } from 'react-device-detect';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import ReactLoading from 'react-loading';

import Slide from "@material-ui/core/Slide";

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';

import FeedingTable from './FeedingTable'

import Autocomplete from '@material-ui/lab/Autocomplete';

import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


import MaterialTable from 'material-table'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}
// var temp = localStorage.getItem('org_branch_id') == '0' ?

//   [{ id: 'patient_invoice', label: 'Invoice' },
//   { id: 'lab_order', label: 'Lab Order' },
//   ]
//   :
//   (localStorage.getItem('org_branch_id') == '1' || localStorage.getItem('org_branch_id') == '42' || localStorage.getItem('org_branch_id') == '3')
//     ?
//     [
//       { id: 'patient_invoice', label: 'View Patient Invoice' },
//       { id: 'lab_order', label: 'View Lab Order' },
//       { id: 'barcode', label: 'Print Barcode' }
//     ]
//     :
//     [
//       { id: 'patient_invoice', label: 'View Patient Invoice' },
//       { id: 'lab_order', label: 'View Lab Order' }
//     ]

const columns =
    [
        { id: 'invoice_no', label: 'Invoice ID' },
        { id: 'patient_id', label: 'Patient ID' },
        { id: 'patient_name', label: 'Patient Name' },
        { id: 'branch_name', label: 'Branch' },
        { id: 'tests_included', label: 'Tests' },
        { id: 'mobile_no', label: 'Mobile No' },
        { id: 'remarks', label: 'Remarks' },
        { id: 'patient_invoice', label: 'Invoice' },
        { id: 'lab_order', label: 'Lab Order' },
        {
            id: 'barcode', label: <p style={{
                textAlign: 'center'
            }}>Barcode (Branches)</p>
        },
        { id: 'receipt', label: 'Receipt' }
    ]
function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}
function to24Hours(d) {
    var h = addZero(d.getHours());
    var m = addZero(d.getMinutes());
    var s = addZero(d.getSeconds());
    return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 1000,
    },
});
function msToTime(seconds) {

    var minutes = parseInt(seconds / 60, 10);
    seconds = seconds % 60;
    var hours = parseInt(minutes / 60, 10);
    minutes = minutes % 60;

    return addZero(hours) + ':' + addZero(minutes);
}

export default function Lab_Tests() {
    const classes = useStyles();
    var date = new Date()
    var temp_role = localStorage.getItem('role')
    date.setDate(date.getDate() - 2);

    const [selected, setSelected] = React.useState({
        reports: []
    })

    const [testRecord, settestRecord] = React.useState({
        "164428": [
            {
                Test_Name: "Pus for Culture & Susceptibility",
                Test_ID: "UR-001-T-0062",
                Test_Aux: "187181"

            }
        ]
    })

    const [OpenCovidTemplate, setCovidTemplate] = React.useState(false)

    const [OpenOfficialCovidTemplate, setOfficialCovidTemplate] = React.useState(false)

    const [patient_invoice, setPatientInvoice] = React.useState(false)

    const [from, setFrom] = React.useState(date)
    const [to, setTo] = React.useState(new Date())
    const [width, height] = [window.innerWidth, window.outerWidth];
    const [panel, setPanel] = React.useState('All')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);


    const [selectedCovid, setSelectedCovid] = React.useState({
        reports: []
    })
    const [selectedOfficialCovidReport, setSelectedOfficialCovidReport] = React.useState({
        reports: []
    })


    const [org_rows, setOrg_Rows] = React.useState([]);
    const [loading, setLoading] = React.useState(true)

    const [covidButton, setCovidButton] = React.useState(false)

    const [panelList, setpanelList] = React.useState([])
    const [laborder, setLabOrder] = React.useState(false)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const [branches, setBranches] = React.useState([])
    const [branch, setBranch] = React.useState("")
    const [selectBranch, setSelectBranch] = React.useState([])
    const [selectOrders, setSelectedOrders] = React.useState([])
    const [state, setState] = React.useState({
        status: 'All',
    });

    //// --------------------------------------
    const [selectedReceipt, setSelectedReceipt] = React.useState("")
    const [selectReceiptOpen, setSelectReceiptOpen] = React.useState(false)

    // 27 Apr 2022
    const [data,setData]=React.useState([
        {
        id: 1,
        title: 'Complete Blood Picture',
       
        },
        {
        id: 2,
        title:"",
        name:"White Blood Cells",
        value: "24,000",
        ref_range:"4,000-10,000",
        unit:'/cmm',
        parentId: 1,
        },
        {
            id: 3,
            title:"",
            name:"RBC Count",
            value: "5.42",
            ref_range:"4.5-5.5",
            unit:'mil/cmm',
            parentId: 1,
        },
        {
            id: 4,
            title:"",
            name:"Hemoglobin Level",
            value: "14.5",
            ref_range:"14 - 18",
            unit:'g/dL',
            parentId: 1,
        },

        {
            id: 5,
            title: 'Urine Routine Examination',
           
            },
            {
            id: 6,
            title:"",
            name:"Color",
            value: "Yellow",
            ref_range:"-",
            unit:'-',
            parentId: 5,
            },
            {
                id: 7,
                title:"",
                name:"Appearance",
                value: "Clear",
                ref_range:"-",
                unit:'-',
                parentId: 5,
            }
       
    ])

    var svgString2Image = (svgString, width, height, format, callback) => {

        var svgStringFormat = window.atob(svgString)
        var temp = svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
        // set default for format parameter
        format = format ? format : 'png';
        // SVG data URL from SVG string
        var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
        // create canvas in memory(not in DOM)
        var canvas = document.createElement('canvas');
        // get canvas context for drawing on canvas
        var context = canvas.getContext('2d');
        // set canvas size
        canvas.width = width;
        canvas.height = height;
        // create image in memory(not in DOM)
        var image = new Image();
        // later when image loads run this
        image.onload = function () { // async (happens later)
            // clear canvas
            context.clearRect(0, 0, width, height);
            // draw image with SVG data to canvas
            context.drawImage(image, 0, 0, width, height);
            // snapshot canvas as png
            var pngData = canvas.toDataURL('image/' + format);
            // pass png data URL to callbac
            callback(pngData)
        }; // end async
        image.src = svgData

    }

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://invoicing.mpl-labs.pk/login"
        }
        if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://invoicing.mpl-labs.pk"
        }
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
            setBranches(response)

        })
        var patient_id = localStorage.getItem('patient_id')
        var role = localStorage.getItem('role')



        var branchID = localStorage.getItem('branch_id')
        var from = new Date();
        var to = new Date();

        var obj = {
            DateStarting:from.toISOString(),
            DateEnding:to.toISOString(),
            branch_id:parseInt(branchID)
        }

        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebTestsListCallUpForResultFeed", {
            method: "POST",

            body: JSON.stringify(obj)
        }).then((res) => res.json()).then((response) => {
            console.log(response)
            var ArrayInvoices=JSON.parse(response[0].ArrayInvoiceNo)
            var ArrayTestName=JSON.parse(response[0].ArrayTestName)
            obj={}
            ArrayInvoices.map((i,index)=>{
                if(obj[i]==undefined){
                    obj[i]=[
                        {
                            Test_Name:ArrayTestName[index]
                        }
                    ]
                }else{
                    obj[i].push({
                            Test_Name:ArrayTestName[index]
                        })
                    
                }
            })
            settestRecord(obj)
            setLoading(false)
        })

        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetAllPanelsList").then((res) => res.json())
            .then((result) => {
                setpanelList(result)
            })
        if (localStorage.getItem('org_branch_id') == '0') {
            fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
                setBranches(response)
                var obj = {}
                response.map((item) => {
                    obj[item.BranchID] = item.BranchName
                })
                setSelectBranch(obj)

                setBranch(localStorage.getItem('branch_id'))
            })
        }


    }, [])
    Date.prototype.hhmm = function () {
        var mm = this.getMinutes();
        var hh = this.getHours();

        return [
            (hh > 9 ? '' : '0') + hh,
            (mm > 9 ? '' : '0') + mm
        ].join(':');
    };

    Date.prototype.ddmmyyy = function () {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [
            (dd > 9 ? '' : '0') + dd,
            (mm > 9 ? '' : '0') + mm,
            this.getFullYear()
        ].join('/');
    };
    //3-may-23
    // const [columnsNew, setColumns] = React.useState([
    //     { title: 'Name', field: 'name' },
    //     { title: 'Surname', field: 'surname', initialEditValue: 'initial edit value' },
    //     { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
    //     {
    //       title: 'Birth Place',
    //       field: 'birthCity',
    //       lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
    //     },
    //   ]);
    
    //   const [dataNew, setDataNew] = React.useState([
    //     { name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
    //     { name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34 },
    //   ]);
    const dataNew = [
        { name: 'John', age: 25 },
        { name: 'Jane', age: 30 },
        { name: 'Bob', age: 40 },
      ];
      
      const columnsNew = [
        { title: 'Name', field: 'name', editable: 'onUpdate' },
        { title: 'Age', field: 'age', type: 'numeric', editable: 'onUpdate' },
      ];
    return (
        <>
            <Paper className={classes.root}>
                <GridContainer style={{ padding: '2em' }}>
                    <GridItem xs={6} sm={6} md={2} lg={2}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="From"
                                format="dd/MM/yyyy"
                                value={from}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setFrom(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </GridItem>
                    {
                        width < 600
                            ?
                            <GridItem xs={6} sm={6} md={0} lg={0}>

                            </GridItem> : ""
                    }

                    <GridItem xs={6} sm={6} md={2} lg={2}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="To"
                                format="dd/MM/yyyy"
                                value={to}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setTo(date)}
                            />
                        </MuiPickersUtilsProvider>

                    </GridItem>
                    {
                        width < 600
                            ?
                            <GridItem xs={6} sm={6} md={0} lg={0}>

                            </GridItem> : ""
                    }

                    {
                        localStorage.getItem('org_branch_id') == '0' ?
                            <GridItem xs={12} sm={12} md={2} lg={2}  >
                                <FormControl variant="filled" className={classes.formControl} style={{ width: "10em" }} >
                                    <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={branch}
                                        onChange={(e) => {
                                            var result = window.confirm('Are you Sure ?. Your want to change Branch?')
                                            if (result) {
                                                setBranch(e.target.value)
                                                localStorage.setItem('branch_id', e.target.value)
                                                localStorage.setItem('branch_name', selectBranch[e.target.value])
                                                window.location.reload()
                                            }

                                        }}
                                    >
                                        <MenuItem value={"0"} disabled>Select</MenuItem>
                                        {
                                            branches.map((item) => {
                                                return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>

                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </GridItem>
                            : null
                    }
                    <GridItem xs={12} sm={12} md={2} lg={2} style={{ margin: 'auto' }} >

                        <Button variant="contained" color="primary" disableElevation disabled={loading}

                        >
                            Search<SearchIcon />
                        </Button>

                    </GridItem>
                </GridContainer>


                {loading && localStorage.getItem('branch_id') != '0' ?
                    <center>
                        <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
                    </center>
                    :
                    <>
                        <GridContainer>
                            {/* <GridItem md={3}>
                                {
                                    Object.keys(testRecord).length>1 ?
<FeedingTable testRecord={testRecord} />
: null
                                }
                                
                            </GridItem> */}
                             <GridItem md={4}>
                             <Autocomplete
                          
                          disablePortal
                          id="combo-box-demo"
                          options={["110022","12122","12112"]}
                          sx={{ width: 300 }}
                          style={{marginLeft:10}}
                          renderInput={(params) => <TextField {...params} label="Invoice Selection" />}
                          value={""}
                          onChange={(event, newInputValue) => {


                          }}
                          
                          />
                          </GridItem>
                          <GridItem md={8}></GridItem>
                          <GridItem md={12}>
                          {/* <MaterialTable
      title="Cell Editable Preview"
      columns={columnsNew}
      data={dataNew}
      cellEditable={{
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          return new Promise((resolve, reject) => {
            console.log('newValue: ' + newValue);
            setTimeout(resolve, 1000);
          });
        }
      }}
    /> */}
     {/* <MaterialTable
      title="Editable Example"
      data={dataNew}
      columns={columnsNew}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            // handle row update logic here
            resolve();
          }),
      }}
    /> */}
                         

                                <MaterialTable
                                icons={tableIcons}
                                title="Invoice No . 110022"
                                data={data}
                                columns={[
                                    { title: 'Title', field: 'title' , editable:'never'},
                                    { title: 'Name', field: 'name' , editable:'never' ,
                                    cellStyle: {
                                        fontWeight:"bold"
                                    
                                    }
                                },
                                    { title: 'Value', field: 'value',
                                     editable: 'always' ,
                                    // editable: rowData => rowData.editable,
                                    cellStyle: {
                                        color: "#039be5",
                                       fontWeight:'bold'
                                      },
                                      headerStyle: {
                                        backgroundColor: '#039be5',
                                      }


                                },
                                    { title: 'Ref Range', field: 'ref_range' , editable:'never' ,
                                
                                    cellStyle: {
                                        color: 'red'
                                      }
                                    
                                },
                                    { title: 'Unit', field: 'unit' , editable:'never'}
                                    
                                ]}
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                enableEditing={true}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                      new Promise((resolve, reject) => {
                                        setTimeout(async () => {
                                            const dataUpdate = [...data];
                                            console.log("dataUpdate"+JSON.stringify( dataUpdate))
                                            const index = oldData.tableData.id;
                                            console.log("index"+index)
                                            console.log("NewData"+JSON.stringify(newData))
                                            
      
                                              // dataUpdate[index].receivedValue = newData.receivedValue;
                                                dataUpdate[index] = newData;
                                                setData([...dataUpdate]);
                                        
                    
                                            resolve();
                                          }, 1000)
                                      }),
                                  }}
                                cellEditable={{
                                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                      return new Promise((resolve, reject) => {
                                        console.log('newValue: ' + newValue);
                                        setTimeout(resolve, 1000);
                                      });
                                    }
                                }}
                                />
                                </GridItem>
  

                        </GridContainer>
                    </>
                }


            </Paper>


        </>
    );
}
