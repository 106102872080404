import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import    InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTitle from './InvoiceTitle'


const InvoiceTableRow =  ({items, invoice}) =>  {
    var counter=0
    const rows =items.map( (item,index) => 
        
        {
        counter++

        return (
            <View >
                {/*counter%9 tha 16-jan-23 ko change kiya 2701417 report me issue tha recp unit msg line 30,40 */}
        {/* 19-jan commit */}
        {console.log(JSON.stringify(invoice.invoiceId))}
      
        {/* { counter%9==0 && invoice.invoice_id==""
        ?
             <View style={ {
                flexDirection: 'row',
                alignItems: 'center',
                width:"100%",
                height: item.reporting_date.includes("2022") ? "140em" : "80em"
                }} key={item.test_title} >
                    </View>
                    :null
        } */}
    {/* es ko absolute kr k lga den ge agr 2nd page ye chahye hoga to*/}
       
        {/* {
            counter==21?  <>
          
        {console.log("mode value is "+counter )}
      
            <InvoiceTableHeader  />
         
            </>: null
        } */}
         {/* {
             counter==39 ? <>
          
        {console.log("mode value is "+counter )}
        <View style={ {
                flexDirection: 'row',
                alignItems: 'center',
                width:"100%",
                // height: ""
                marginTop:"2%"
                }} key={item.sn} >
                    </View>
            <InvoiceTableHeader  />
         
            </>: null
        } */}
          {/* {
             counter==57? <>
          
        {console.log("mode value is "+counter )}
        <View style={ {
                flexDirection: 'row',
                alignItems: 'center',
                width:"100%",
                // height: ""
                marginTop:"2%"
                }} key={item.sn} >
                    </View>
            <InvoiceTableHeader  />
         
            </>: null
        } */}
        <View style={ {
        flexDirection: 'row',
        borderBottomColor: 'BLACK',
        borderBottomWidth: 0.5,
        // borderTopWidth: counter%22 ? 0.5 :0,
        borderTopWidth: counter==21 || counter==41||counter==61 || counter==81? 0.5 :0,

        alignItems: 'center',
        width:"100%",
        fontSize:7,
        fontStyle: 'bold',
        
       
        }} key={item.sn} >
             <Text style={{ 

        width: '5%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        height:'100%',
        padding:5,  
        fontSize:7,
        }}>{(index+1)}</Text>
         {/* <Text style={{ 
                 
        width: '37%',
        fontFamily: 'Helvetica-Bold',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        fontSize:6,
        height:'100%',
        padding:5
        
        
        }}>{item.test_title}</Text> */}
                    {/* <View style={{

width: '19%',
// fontFamily: 'Helvetica-Bold',
// textAlign: 'left',
borderLeftColor: 'black',
borderLeftWidth: 0.5,
// fontSize: 6,
height: '100%',
padding: 5


}}>
                <Text style={{
                    fontFamily: 'Helvetica-Bold',
                    textAlign: 'left',   
                    fontSize: 6,
                }}>{item.invoiceId}</Text>
    
</View> */}
 <Text style={{ 
                 
                 width: '19%',
                 textAlign: 'left',
                 borderLeftColor: 'black',
                 borderLeftWidth: 0.5,
                 
                 fontSize:6,
                 padding: 5,
                 height:'100%'
                 
                 
                 }}>
                    {item.invoiceId}</Text>
         <Text style={{ 
                 
        width: '19%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        
        fontSize:6,
        padding: 5,
        height:'100%'
        
        
        }}>{item.date}</Text>
         <Text style={{ 
                 
        width: '30%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        
        fontSize:6.5,
        height:'100%',
        padding: 5,
        
        }}>{item.patientName}</Text>
         <Text style={{ 
                 
        width: '13.1%',
        textAlign: 'right',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        height:'100%',
        
        fontSize:6.5,
        padding: 5,
        
        
        }}>{item.outstanding}</Text>
            <Text style={{ 
                 
        width: '13.8%',
        height:'100%',
        textAlign: 'right',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        fontSize:6.5,
        borderRightWidth: 0.5,
        textOverflow: 'auto',
        padding: 5
        
        }}>{item.received}</Text>
     
     </View>
     { counter==20 
        ?
             <View style={ {
                flexDirection: 'row',
                alignItems: 'center',
                width:"100%",
                height: "2.7em"
                }} key={item.test_title} >
                    </View>
                    :null
        }
        {  counter==40 
        ?
             <View style={ {
                flexDirection: 'row',
                alignItems: 'center',
                width:"100%",
                height: "1.7em"
                }} key={item.test_title} >
                    </View>
                    :null
        }
         {  counter==60 
        ?
             <View style={ {
                flexDirection: 'row',
                alignItems: 'center',
                width:"100%",
                height: "1.7em"
                }} key={item.test_title} >
                    </View>
                    :null
        }
         {  counter==80 
        ?
             <View style={ {
                flexDirection: 'row',
                alignItems: 'center',
                width:"100%",
                height: "1.7em"
                }} key={item.test_title} >
                    </View>
                    :null
        }
     </View>
    )
        })
    

        return (<View>{rows}</View>)
    
};
  
  export default InvoiceTableRow