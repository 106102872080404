import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import InvoiceItemsTable from './InvoiceItemsTable'
import logo from '../mpl-logo-simple.png'

const styles = StyleSheet.create({
    page: {
        paddingTop: 150,
        paddingBottom: 140,
        fontSize: 8,
        paddingLeft: 10,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },

    logo: {
        width: 130,
        height: 60,
        marginTop: -70
    },
    barcode: {
        width: 145,
        height: 18,
        marginLeft: '5em'
    }
});


const Invoice = ({ invoice }) => {

    return (<Document>
        <Page style={styles.page} size="A5" wrap
        >
            <View fixed style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                top: 0,
                position: 'absolute',
                paddingLeft: 25,
                alignItems: "center",
                paddingRight: 10,
                paddingTop: 10
            }} >

                <View
                    style={{
                        width: "30%"
                    }}
                >
                    <Image style={{

                        width: invoice.BranchExcLetterhead ? 110 : 200,
                        height: invoice.BranchExcLetterhead ? 100 : 100,
                        marginTop: -30,
                        marginLeft: invoice.BranchExcLetterhead ? 0 : -25

                    }} src={invoice.BranchExcLetterhead ? require("../smdc.png") : logo} />
                    <View style={{
                        width:"300%",
                        marginTop:15,
                        marginLeft:-5
                    }}>
                     <InvoiceTitle item={invoice} />
                     </View>
                </View>

                <View style={{ marginTop: -80, marginLeft: 20, width: "30%" , flexDirection:'column'}} >
                    
                     <View style={{
                        
                    }}>
                        <Text style={{ marginLeft:-12, textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 18 }}>Invoice</Text>
                    </View>

                    <View 
                    style={{
                        flexDirection:"column"
                    }}>

                    <View style={{
                        margin: 'auto', width: 70, marginLeft: 15, backgroundColor: '#F2F0F0', borderWidth: 1,
                        borderColor: 'black'
                    }}>
                        <Text style={{ paddingTop: '3em', paddingLeft: '3.5em', paddingRight: '3.5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 7 }}>FSLS ID : {invoice.invoice_id}</Text>
                    </View>
                    <View style={{
                        margin: 'auto', width: 70, marginLeft: 15
                       
                    }}>
                        <Text style={{ paddingTop: '3em', paddingLeft: '3.5em', paddingRight: '3.5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 7.5, textDecoration:'underline' }}>MR # : {invoice.patient_id}</Text>
                    </View>
                    </View>
                </View>
                <View style={{ width: "40%", marginLeft: -10 }}  >
                <Text style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 5,
                            textAlign:"right",
                            marginBottom: 1,
                            marginTop:3


                        }}>{"DOC No:FSLS-GEN-F040 | Issue No: 01 | Issue Date:27-05-2022"}</Text>
                    <Image style={styles.barcode} src={invoice.barcode} />
                    <View

                    >
                        <Text style={{
                            marginLeft: '5em',
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            marginBottom: 10,
                            marginTop: 2,


                        }}>{invoice.toAddress + " " + invoice.name}</Text>
                    </View>
                    {/* {invoice.invoice_id==2101409 || invoice.invoice_id==2701404 ||invoice.invoice_id==1901385? */}
                    {/* {invoice.invoice_id==2101409 || invoice.invoice_id==2701404 ||invoice.invoice_id==1901385||invoice.invoice_id==5801467 || invoice.invoice_id== "0401479" ||invoice.invoice_id==9701419||invoice.invoice_id==5801466 || invoice.invoice_id==5901519  ? */}
                    {/* {invoice.invoice_id==2101409 || invoice.invoice_id==2701404 ||invoice.invoice_id==1901385||invoice.invoice_id==5801467 || invoice.invoice_id== "0401479" ||invoice.invoice_id==9701419||invoice.invoice_id==5801466 || invoice.invoice_id==5901519 || invoice.invoice_id==7101439 ||invoice.invoice_id==4401512||invoice.invoice_id==9201456 || invoice.invoice_id== 9201457  ? */}
                      {/* {(invoice.age_gender.includes("6739")) ||(invoice.age_gender.includes("6740"))? this will apply for other than human because age is calculated from hazrat adam jab 6740 se b zyada ho gae to es me aur include dal den ge*/}
                  
                      {(invoice.age_gender.includes("6739")) ||(invoice.age_gender.includes("6740"))||(invoice.age_gender.includes("6741"))?
                     <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        borderTopColor: 'black',
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                        borderTopWidth: 0.75

                    }}>Age/Gender :  N/A</Text>: <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        borderTopColor: 'black',
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                        borderTopWidth: 0.75

                    }}>Age/Gender :  {invoice.age_gender}</Text> }
                    {/* <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        borderTopColor: 'black',
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                        borderTopWidth: 0.75

                    }}>Age/Gender :  {invoice.age_gender}</Text> */}
                        {/* // for new MR panel 3-JAN-2023 */}

                        {
                        invoice.if_panelMR_cnic_father&& invoice.father_husband!="null"
                        
                            ? <Text style={{
                                marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                                borderBottomColor: 'black',
                                borderBottomWidth: 0.75,
                            }}>Father/Husband : {invoice.father_husband}</Text>
                            : null
                    }
                      {
                        invoice.if_panelMR_cnic_father&& invoice.cnic!=""
                            ? <Text style={{
                                marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                                borderBottomColor: 'black',
                                borderBottomWidth: 0.75,
                            }}>CNIC: {invoice.cnic}</Text>
                            : null
                    }
                    <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                    }}>Contact # :  {invoice.contact}</Text>
                    {
                        invoice.flight_no == "" ?
                            <Text style={{
                                marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                                borderBottomColor: 'black',
                                borderBottomWidth: 0.75,
                            }}>Ref By : <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 7 }}>{invoice.Ref_By}</Text></Text> : null
                    }

                    {
                        invoice.Ref_No != "" && invoice.flight_no == ""
                            ? <Text style={{
                                marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                                borderBottomColor: 'black',
                                borderBottomWidth: 0.75,
                            }}>Ref No : {invoice.Ref_No}</Text>
                            : null
                    }
                    {
                        invoice.Entitlement_Name != ""
                            ?
                            <Text style={{
                                marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                                borderBottomColor: 'black',
                                borderBottomWidth: 0.75,
                            }}>Entitlement : <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 7 }}>{invoice.Entitlement_Name}</Text></Text>
                            :
                            null
                    }
                    {
                        invoice.flight_no != "" ?
                            <View>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '5em', fontFamily: 'Helvetica-Bold', fontSize: 7,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.75,
                                }}>Flight No : {invoice.flight_no}</Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '5em', fontFamily: 'Helvetica-Bold', fontSize: 7, borderBottomColor: 'black',
                                    borderBottomWidth: 0.75,
                                }}>Flight Date : {invoice.flight_date}</Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '5em', fontFamily: 'Helvetica-Bold', fontSize: 7, borderBottomColor: 'black',
                                    borderBottomWidth: 0.75,
                                }}>Flight Time : {invoice.flight_time}</Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '5em', fontFamily: 'Helvetica-Bold', fontSize: 7, borderBottomColor: 'black',
                                    borderBottomWidth: 0.75,
                                }}>Passport No : {invoice.passport_no}</Text>
                            </View>
                            : <View>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '15em', fontFamily: 'Helvetica-Bold', fontSize: 7,

                                }}></Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '15em', fontFamily: 'Helvetica-Bold', fontSize: 7,

                                }}></Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '15em', fontFamily: 'Helvetica-Bold', fontSize: 7,
                                }}></Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '15em', fontFamily: 'Helvetica-Bold', fontSize: 7,

                                }}></Text>
                            </View>
                    }
                </View>
            </View>
                      {/* // 23-jan  */}
                      <Text style={{
    // color: 'white',
      color: 'black',
      fontSize: 9,
      fontFamily: 'Helvetica',
      textAlign: 'left',
      position:"absolute",
      top:147,
      left:20
  }}>{invoice.Panel_Name.includes("Air MPL") ? "COVID-19 PCR FOR KSA": invoice.Panel_Name}</Text>
            <View style={{ width: '100%', height: '100%',
             //ye marginTop khtm kiya 19-jan
            
            // 23-jan marginTop:invoice.Panel_Name!=""?10: 0,
        //    invoice.Panel_Name!="" &&invoice.reports.length>7?18:invoice.Panel_Name!="" &&invoice.reports.length<7? 5 ye condition hai jab 
                  
        marginTop:invoice.Panel_Name!="" &&invoice.reports.length>7?18:invoice.Panel_Name!="" &&invoice.reports.length<7? 10: 11,
            //  marginTop: -20, 
             paddingRight: 10, paddingLeft: 10 }}

            >

                <InvoiceItemsTable invoice={invoice}
                />

            </View>



            <View fixed style={{ position: 'absolute', width: '100%', bottom: 0, flexDirection:'column' }}>
          {/* 21-jan-23 */}
            {/* {
                    invoice.pos!=""
                    ?
            <View
                   style={{
                    display:"flex",
                    flexDirection:"column",
                    marginLeft: '10em', 
                   }}
                >
            <View
                   style={{
                    display:"flex",
                    flexDirection:"row"
                   }}
                >
               
                      <Image style={{

                        width: 42,
                        height: 42,
                        }} src={ require("../pos.png")} />
                         <Image style={{

                            width: 42,
                            height: 42,
                            }} src={invoice.pos} />
                    </View>

                    <Text style={{
                           fontFamily: 'Helvetica-Bold',
                                    fontSize: 8,
                                }}>{invoice.pos_id}</Text>
                                 </View>

                                 :
                                 null 
                                 } */}
            <View
                    style={{
                        width: "100%"
                    }}
                >
                    <Image style={{

                        width: 420,
                        height: 130

                    }} src={ require("../mpl-qr-footer-simple.jpg")} />
                    <Text fixed style={{ width: "100%", left: 0, paddingLeft: 20, position: "absolute", fontSize: 6,top:100 }} render={({ pageNumber, totalPages }) => (
                            "Page " + `${pageNumber} / ${totalPages}`
                        )} />
                    <View style={{
                        position:"absolute"
                    }}>
                            <Text style={{
                                    marginLeft: '10em', 
                                    marginTop:"60em",
                                    paddingTop: '5em', 
                                    fontSize: 10,
                                    marginLeft:20
                                }}>Login ID : <Text style={{    fontFamily: 'Helvetica-Bold', }}>{invoice.patient_id}</Text></Text>
                                 <Text style={{
                                    marginLeft: '10em', 
                                    paddingTop: '5em', 
                                    fontSize: 10,
                                    marginLeft:20
                                }}>Password : <Text style={{    fontFamily: 'Helvetica-Bold', }}>{invoice.password}</Text></Text>

                                </View>
                                
                </View>
            
            </View>
        </Page>
    </Document>
    )
}

export default Invoice