import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
// import InvoiceTitle from './InvoiceTitle'
// import InvoiceItemsTable from './InvoiceItemsTable'
import logo from '../blank_header.png'

// import GpLogo from '../../images/InvoicingHeader.jpg'
import GpLogo from '../blank_header.png'
import maleIcon from '../../images/malenew.png'
import femaleIcon from '../../images/femalenew.jpeg'




const styles = StyleSheet.create({
    page: {
        paddingTop: 150,
        // paddingBottom: 140,
        // fontSize: 8,
        // paddingLeft: 10,
        // lineHeight: 1.5,
        // flexDirection: 'column'
        // paddingTop: 200,
        paddingBottom: 80,
        fontSize: 8,
        paddingLeft: 10,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },

    logo: {
        width: 220,
        height: 120,
        // marginTop: -70
    },
    logodown: {
        width: 120,
        height: 100,
        // padding:5,
        // borderRadius:50,
        borderColor:"black",borderWidth:1,borderRadius:20,borderStyle:"solid"
        // marginTop: -70
    },
    logoRight: {
        width: 160,
        height: 100,
        // marginTop: -70
    },
    barcode: {
        width: 160,
        height: 20,
        marginLeft: '5em'
    }
});


const Invoice = ({ invoice }) => {

    return (<Document>
        <Page style={styles.page} size="A4" wrap
        >
            {console.log("PrescriptionWithoutLogo -> invoiceFemale.js 70")}

            {console.log(invoice)}
            <View fixed style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                top: 0,
                position: 'absolute',
                paddingLeft: 25,
                alignItems: "center",
                paddingRight: 10,
                paddingTop: 2,
                //  backgroundColor:"green",
                 height:"20%"
            }} >
                <View fixed  style={{
                    //  width: "30%",
                    //  backgroundColor:"blue",
                position: 'absolute',
                // top:10,
                // left:15,
                
                top:-10,
                left:-2,

                     //25-feb-2023 for new pdf
                    //  marginTop:55 
                     }}  >
              
                    <Image style={styles.logo} src={GpLogo} />

                </View>
                <View fixed style={{
                    //  width: "30%",
                      marginLeft: 220,
                position: 'absolute',top:10,
left:200,

                    //  backgroundColor:"blue",
                    //  position:"absolute"
                     //25-feb-2023 for new pdf
                    //  marginTop:55 
                     }}  >
              
                    {/* <Image style={styles.logoRight}  src={GpLogo} /> */}
                    <Image style={styles.logoRight}  src={invoice.doctor_image_LH?invoice.doctor_image_LH:logo} />

                    {/* <View

                    >
                        <Text style={{
                            // marginLeft: '5em',
                            // fontFamily: 'Helvetica-Bold',
                            fontSize: 8,
                            // marginBottom: 10,
                            marginTop: 5,
                            textAlign:"right",
                            color:"black"


                        }}>Date:28/03/24</Text>
                         <Text style={{
                            // marginLeft: '5em',
                            // fontFamily: 'Helvetica-Bold',
                            fontSize: 8,
                            // marginBottom: 10,
                            marginTop: 0,
                            textAlign:"right",
                            color:"black"



                        }}>Physician : Dr Hafiz Aamir Nawaz</Text>
                    </View> */}

                    
                  
                  
                </View>
                </View> 
                {/* 2nd row */}
                <View fixed style={{
                width: '96.5%',
                display: 'flex',
                flexDirection: 'row',
                top: 120,
                position: 'absolute',
                // paddingLeft: 25,
                alignItems: "center",
                // borderWidth: 1,
                borderColor: 'black',
                borderLeftColor:"white",
                borderRightColor:"white",
                borderTopWidth:1,
                borderBottomWidth:1,
                left:"2.5%"
                // paddingRight: 10,
                // paddingTop: 2,
                //  backgroundColor:"green",
                //  height:"20%"
            }} >
                 <View style={{padding:3}}>
                    {/* <Image style={styles.logodown}  src={PersonLogo} /> */}
                    {/* 16-apr-2024 */}
                    {console.log("check gender")}
                    {console.log(invoice.gender)}
                    <Image style={styles.logodown}  src={invoice.Base64_PicturePatient!=null?invoice.Base64_PicturePatient:femaleIcon} />
      

                    </View>
                    <View
style={{marginLeft:15}}
                    >
                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 10,
                            // marginBottom: 10,
                            marginTop: -25,
                            // textAlign:"right",
                            color:"black"


                        }}><Text style={{  fontFamily: 'Helvetica-Bold',}}>Name :</Text> {invoice.name}</Text>
                        <Text style={{
                            // marginLeft: '5em',
                            // fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            // marginBottom: 10,
                            // marginTop: 5,
                            // textAlign:"right",
                            color:"black"


                        }}><Text style={{  fontFamily: 'Helvetica-Bold',}}> Age  :</Text> {invoice.age_gender}</Text>
                         {/* <Text style={{
                            // marginLeft: '5em',
                            // fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            // marginBottom: 10,
                            marginTop: 0,
                            // textAlign:"right",
                            color:"black"



                        }}><Text style={{  fontFamily: 'Helvetica-Bold',}}> Phone :</Text> 03315932146</Text> */}
                         <Text style={{
                            // marginLeft: '5em',
                            // fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            // marginBottom: 10,
                            marginTop: 0,
                            // textAlign:"right",
                            color:"black"



                        }}><Text style={{  fontFamily: 'Helvetica-Bold',}}> MR #:</Text>{invoice.patient_id}</Text>
                        <Text style={{
                            // marginLeft: '5em',
                            // fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            // marginBottom: 10,
                            marginTop: 0,
                            // textAlign:"right",
                            color:"black"



                        }}><Text style={{  fontFamily: 'Helvetica-Bold',}}> FSLS ID :</Text> {invoice.invoice_id}</Text>
                    </View>
                    <View
style={{marginLeft:15}}
                    >
                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 10,
                            // marginBottom: 10,
                            marginTop: -42,
                            // textAlign:"right",
                            color:"black"


                        }}><Text style={{  fontFamily: 'Helvetica-Bold',}}>Date :</Text> {invoice.invoice_date} {invoice.invoice_time}</Text>

                    </View>


                    
                  
                  
                </View>
                {/* Rx line */}
                <View fixed style={{
                width: '21%',
                display: 'flex',
                flexDirection: 'column',
                top: 228,
                position: 'absolute',
                // paddingLeft: 25,
                // alignItems: "center",
                // borderWidth: 1,
                // borderColor: 'black',
                borderRightColor:"black",
                // borderRightColor:"white",
                borderRightWidth:1,
                // borderBottomWidth:1,
                left:"2.5%",
                // paddingRight: 10,
                // paddingTop: 2,
                //  backgroundColor:"red",
                //  height:"20%"
                paddingBottom:"130%"
            }} >

<View
style={{marginTop:10,marginLeft:2}}
                    >
                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 13,
                            // marginBottom: 10,
                            // marginTop: -42,
                            // textAlign:"right",
                            color:"black",
                 fontFamily: 'Helvetica-Bold',



                        }}>Rx</Text>

                    </View>
                    {/* vitals area in Rx */}
                    <View
style={{marginLeft:2}}
                    >
                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 9,
                            // marginBottom: 10,
                            // marginTop: -42,
                            // textAlign:"right",
                            color:"black",
                            textDecoration:"underline",
                 fontFamily: 'Helvetica-Bold',



                        }}>Vitals</Text>
                        <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 7.5,
                            // marginBottom: 10,
                            // marginTop: -42,
                            // textAlign:"right",
                            color:"black",
                            // textDecoration:"underline",
                //  fontFamily: 'Helvetica-Bold',
                marginTop:5



                        }}>BP  <Text>____________ </Text>mmHg</Text>
                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 7.5,
                            // marginBottom: 10,
                            // marginTop: -42,
                            // textAlign:"right",
                            color:"black",
                            // textDecoration:"underline",
                //  fontFamily: 'Helvetica-Bold',

                marginTop:8


                        }}>Pulse <Text>__________ </Text>bpm</Text>
                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 7.5,
                            // marginBottom: 10,
                            // marginTop: -42,
                            // textAlign:"right",
                            color:"black",
                            // textDecoration:"underline",
                //  fontFamily: 'Helvetica-Bold',
                marginTop:8




                        }}>Temp. <Text>__________ </Text>°F</Text>
                        <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 7.5,
                            // marginBottom: 10,
                            // marginTop: -42,
                            // textAlign:"right",
                            color:"black",
                            // textDecoration:"underline",
                //  fontFamily: 'Helvetica-Bold',
                marginTop:8




                        }}>SpO2 <Text>__________ </Text>%</Text>
                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 7.5,
                            // marginBottom: 10,
                            // marginTop: -42,
                            // textAlign:"right",
                            color:"black",
                            // textDecoration:"underline",
                //  fontFamily: 'Helvetica-Bold',
                marginTop:8



                        }}>Height <Text>_________ </Text>cms</Text>
                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 7.5,
                            // marginBottom: 10,
                            // marginTop: -42,
                            // textAlign:"right",
                            color:"black",
                            // textDecoration:"underline",
                //  fontFamily: 'Helvetica-Bold',
                marginTop:8



                        }}>Weight <Text>_________ </Text>Kgs</Text> 
                        <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 7.5,
                            // marginBottom: 10,
                            // marginTop: -42,
                            // textAlign:"right",
                            color:"black",
                            // textDecoration:"underline",
                //  fontFamily: 'Helvetica-Bold',

                marginTop:8


                        }}>BMI  <Text>___________ </Text></Text> 
                         

                    </View>
                     {/* vitals area in Rx */}
                       
                    {/* Radiology Investigation */}

                     {/* vitals area in Rx */}
                     <View
style={{marginLeft:2}}
                    >
                         <Text style={{
                                fontSize: 9,
                            color:"black",
                            textDecoration:"underline",
                 fontFamily: 'Helvetica-Bold',
                 marginTop:8



                        }}>Radiology Investigations </Text>
                                               <Text style={{
                     
                            fontSize: 7.5,           
                            color:"white",                    
                marginTop:9

                        }}><Text>__________________ </Text></Text>
                          <Text style={{                    
                            fontSize: 7.5,
                            color:"white",
                marginTop:9
                        }}><Text>__________________ </Text></Text>
                             <Text style={{
                                             fontSize: 7.5,
                            color:"white",
                marginTop:9
                        }}> <Text>__________________ </Text></Text>
                          <Text style={{
                                     fontSize: 7.5,  
                            color:"white",
                marginTop:9

                        }}> <Text>__________________ </Text></Text>
                       
                    </View>
                     {/* Lab Investigation */}
                    <View
style={{marginLeft:2}}
                    >
                         <Text style={{     
                            fontSize: 9,
                            color:"black",
                            textDecoration:"underline",
                 fontFamily: 'Helvetica-Bold',
                marginTop:8
                        }}>Lab Investigations</Text>
                        <Text style={{                     
                            fontSize: 7.5,
                            color:"white",
                marginTop:9
                        }}> <Text>__________________ </Text></Text>
                          <Text style={{
                                            fontSize: 7.5,  
                            color:"white",
                marginTop:9
                        }}> <Text>__________________ </Text></Text>
                             <Text style={{                 
                            fontSize: 7.5,
                            color:"white",
                marginTop:9
                        }}> <Text>__________________ </Text></Text>
                          <Text style={{ 
                            fontSize: 7.5,
                            color:"white",
                marginTop:9
                        }}><Text>__________________ </Text></Text>
                             <Text style={{ 
                            fontSize: 7.5,
                            color:"white",             
                marginTop:9
                        }}> <Text>__________________ </Text></Text>
                          <Text style={{
                                         fontSize: 7.5,
                            color:"white",
                marginTop:9
                        }}> <Text>__________________ </Text></Text>
                        
                    </View>
            </View>
                {/* Medication area */}
                <View fixed style={{
                width: '60%',
                display: 'flex',
                flexDirection: 'column',
                top: 240,
                // left:500,
                position: 'absolute',
                
                left:"27%",
               
            }} >


                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 10,
                           
                            color:"black",
                 fontFamily: 'Helvetica-Bold',



                        }}>Medication</Text>

                  
                 
            </View>
                  {/* Doctor Signature Area */}
                  <View fixed style={{
                width: '30%',
                display: 'flex',
                flexDirection: 'column',
                top: 740,
                // left:500,
                position: 'absolute',
                // backgroundColor:"green",
                borderColor: 'black',
               
                borderTopWidth:1,
                // borderBottomWidth:1,
                
                left:"66%",
               
            }} >


                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 10,
                           
                            color:"black",
                 fontFamily: 'Helvetica-Bold',
                
                 



                        }}>Doctor Signature</Text>

                  
                 
            </View>
                       {/* Doctor Signature Area */}
                       {/* <View fixed style={{
                width: '30%',
                display: 'flex',
                flexDirection: 'column',
                top: 740,
                // left:500,
                position: 'absolute',
                // backgroundColor:"green",
                borderColor: 'black',
               
                // borderTopWidth:1,
                borderBottomWidth:1,
                
                left:"66%",
               
            }} >


                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 10,
                           
                            color:"black",
                 fontFamily: 'Helvetica-Bold',
                
                 



                        }}>Doctor Signature</Text>

                  
                 
            </View> */}
                          {/* Address Area */}
                          <View fixed style={{
                width: '80%',
                display: 'flex',
                flexDirection: 'column',
                top: 800,
                // left:500,
                position: 'absolute',
                // backgroundColor:"green",
                // borderColor: 'black',
               
                // borderTopWidth:1,
                // borderBottomWidth:1,
                
                left:"11%",
               
            }} >


                        
<Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 9,
                            alignSelf:"center",
                           
                            color:"black",
                //  fontFamily: 'Helvetica-Bold',
                
                 



                        }}>AL-Rehman Plaza, Pindi Road , Chakwal </Text>

                  
                 
            </View>
            <View fixed style={{
                width: '80%',
                display: 'flex',
                flexDirection: 'column',
                top: 812,
                // left:500,
                position: 'absolute',
                // backgroundColor:"red",
                // borderColor: 'black',
               
                // borderTopWidth:1,
                // borderBottomWidth:1,
                
                left:"11%",
               
            }} >


                         <Text style={{
                            // marginLeft: '5em',
                            
                            fontSize: 9,
                            alignSelf:"center",
                           
                            color:"black",
                //  fontFamily: 'Helvetica-Bold',
                
                 



                        }}>For appointment contact : 0543-551628-29  </Text>

                  
                 
            </View>
                {/* <View fixed  style={{
                    //  width: "30%",
                     backgroundColor:"blue",
                position: 'absolute',
                top:10,
                left:20,
                

                     //25-feb-2023 for new pdf
                    //  marginTop:55 
                     }}  >
              
                    <Image style={styles.logo} src={GpLogo} />

                </View> */}
           
                {/* </View> */}
         
            {/* <View fixed style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                top: 0,
                position: 'absolute',
                paddingLeft: 25,
                alignItems: "center",
                paddingRight: 10,
                paddingTop: 2,
                 backgroundColor:"green"
            }} >


                <View fixed style={{
                     width: "40%",
                    //   marginLeft: 220,
                position: 'absolute',top:120,
                left:20,
display:"flex",
flexDirection:"row",

                    //  backgroundColor:"blue",
                    //  position:"absolute"
                     //25-feb-2023 for new pdf
                    //  marginTop:55 
                     }}  >
              <View style={{}}>
                    <Image style={styles.logodown}  src={PersonLogo} />
                    </View>
                    <View
style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column",marginLeft:5}}
                    >
                         <Text style={{
                            // marginLeft: '5em',
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            // marginBottom: 10,
                            marginTop: 5,
                            // textAlign:"right",
                            color:"black"


                        }}>Ahmer Sajjad</Text>
                        <Text style={{
                            // marginLeft: '5em',
                            // fontFamily: 'Helvetica-Bold',
                            fontSize: 8,
                            // marginBottom: 10,
                            // marginTop: 5,
                            // textAlign:"right",
                            color:"black"


                        }}>Age : 23 Y & 2 M</Text>
                         <Text style={{
                            // marginLeft: '5em',
                            // fontFamily: 'Helvetica-Bold',
                            fontSize: 8,
                            // marginBottom: 10,
                            marginTop: 0,
                            // textAlign:"right",
                            color:"black"



                        }}>Phone # 03315932146</Text>
                         <Text style={{
                            // marginLeft: '5em',
                            // fontFamily: 'Helvetica-Bold',
                            fontSize: 8,
                            // marginBottom: 10,
                            marginTop: 0,
                            // textAlign:"right",
                            color:"black"



                        }}>Patient ID : 020521151</Text>
                    </View>

                    
                  
                  
                </View>
                <View style={{
                    //25-feb-2023 for new pdf
                     marginTop: -70, marginLeft: 20, width: "30%" , flexDirection:'column',
                     backgroundColor:"grey"
                    // marginTop: -60, marginLeft: 23, width: "30%" , flexDirection:'column'

                     }} >
                    
                     <View style={{
                        
                    }}>
                        <Text style={{ textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 12 }}>Prescription</Text>
                    </View>

                    <View 
                    style={{
                        flexDirection:"column"
                    }}>
 <View style={{
                        margin: 'auto', width: 75, marginLeft: 12, backgroundColor: '#F2F0F0', borderWidth: 1,
                        borderColor: 'black'
                    }}>
                        <Text style={{ paddingTop: '3em', paddingLeft: '3.5em', paddingRight: '3.5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 7 }}>GPHC ID : {invoice.invoice_id}</Text>
                    </View>
                   
                    <View style={{
                        margin: 'auto', width: 70, marginLeft: 15
                       
                    }}>
                        <Text style={{ paddingTop: '3em', paddingLeft: '3.5em', paddingRight: '3.5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 7.5, textDecoration:'underline' }}>MR # : {invoice.patient_id}</Text>
                    </View>
                    </View>
                </View>
               
            </View> */}
           
        </Page>
    </Document>
    )
}

export default Invoice