import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import    InvoiceTableHeader from './InvoiceTableHeader'



const InvoiceTableRow =  ({items}) =>  {
    // es items me invoice.reports send hua hai jis me title,fee,disc,netVlue one by one all test vki aa rhi hn
    var counter=0
    const rows =items.map( item => 
        
        {
        counter++

        return (
            <View>
       
        <View style={ {
        flexDirection: 'row',
        borderBottomColor: 'BLACK',
        borderBottomWidth: 0.5,
        borderTopWidth: counter%10 ? 0.5 :0,
        alignItems: 'center',
        width:"100%",
        fontSize:3.5,
        fontStyle: 'bold'
       
        }} key={item.test_title} >
             <Text style={{ 

        width: '10%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        height:'100%',
        padding:1,  
        fontSize:3.5,
        }}>{item.sn}</Text>
         <Text style={{ 
                 
        width: '45%',
        fontFamily: 'Helvetica-Bold',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        fontSize:3.5,
        height:'100%',
        padding:1
        
        
        }}>{item.test_title}</Text>
       
         <Text style={{ 
                 
        width: '15%',
        textAlign: 'right',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        
        fontSize:3.5,
        height:'100%',
        padding: 1,
        
        }}>{item.branch.includes('Islamabad') ? item.fee : '6500.00'}</Text>
         <Text style={{ 
                 
        width: '15%',
        textAlign: 'right',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        height:'100%',
        
        fontSize:3.5,
        padding: 1,
        
        
        }}>{item.branch.includes('Islamabad') ? item.disc : '0'}</Text>
            <Text style={{ 
                 
        width: '15%',
        height:'100%',
        textAlign: 'right',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        fontSize:3.5,
        borderRightWidth: 0.5,
        textOverflow: 'auto',
        padding: 1
        
        }}>{item.branch.includes('Islamabad') ? item.net_value : '6500.00'}</Text>
     
     </View>
     </View>
    )
        })
    

        return (<View>{rows}</View>)
    
};
  
  export default InvoiceTableRow