import React ,{useEffect,Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import {PDFViewer} from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';
import EditPatient from './EditPatient'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const columns = 
  [{ id: 'id', label: 'Patient ID' },
  { id: 'name', label: 'Name' },
  { id: 'mobile', label: 'Mobile No' },
  { id: 'cnic', label: 'CNIC' },
  { id: 'passport', label: 'Passport' },
  {id:'edit' , label:'Edit Patient'}
]
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
 return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime (ms) {
  var seconds = (ms/1000);
  var minutes = parseInt(seconds/60, 10);
  seconds = seconds%60;
  var hours = parseInt(minutes/60, 10);
  minutes = minutes%60;
  
  return addZero(hours) + ':' + addZero(minutes) ;
}

export default function Lab_Tests() {
  const classes = useStyles();
  var date=new Date()
  var temp_role=localStorage.getItem('role')
    date.setDate(date.getDate() - 1);
  

  const [selected,setSelected]=React.useState({
    reports:[]
  })
  
  const [patient_invoice,setPatientInvoice]=React.useState(false)
  const [invoiceno,setInvoiceNo]=React.useState("")

  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel,setPanel]=React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows,setRows]= React.useState([]);
  const [org_rows,setOrg_Rows]= React.useState([]);
  const [loading,setLoading]=React.useState(false)
  const [panelList,setpanelList]=React.useState([])
  const [laborder,setLabOrder]=React.useState(false)

  const [patient_id,setPatientID]=React.useState('')
  const [patient_name,setPatientName]=React.useState('')
  const [mobile_number,setMobileNumber]=React.useState('')
  const [cnic,setCnic]=React.useState('')
  const [passport,setPassport]=React.useState('')

  const [to,setTo]=React.useState(new Date())
  var date_from=new Date()
  date_from.setDate(new Date().getDate()-1)
  const [from,setFrom]=React.useState(date_from)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [OpenEdit,setOpenEdit]=React.useState(false)
  const [state, setState] = React.useState({
    status:'All',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    if(localStorage.getItem('user_id')==null || localStorage.getItem('user_id')==undefined){
      localStorage.clear()
      window.location.href="https://invoicing.fslabs.com.pk"
    }
    if(localStorage.getItem('org_branch_id')==null ||localStorage.getItem('org_branch_id')==undefined ){
      localStorage.clear()
      window.location.href="https://invoicing.fslabs.com.pk/login"
    }
    var vSearchStr={
      "User_ID" :localStorage.getItem('user_id'),
      "Menu_Option" :"Overall Edit Patient"
  }
    fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebCheckUserStatusActiveAccess",{
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify(vSearchStr)
    }).then(res=>res.json()).then((res)=>{
      console.log(res)
      if(res[0].Option_Status=="Authorized"){
        
      }else{
        alert("Sorry, You can't Edit Patient Record")
        // localStorage.clear()
        window.location.href = "https://invoicing.fslabs.com.pk/"
      }
    })
    
     
  },[])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes(); 
    var hh = this.getHours();

    return [
        (hh > 9 ? '' : '0') + hh,
        (mm > 9 ? '' : '0') + mm
    ].join(':');
};

Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
  return (
    <>
    <Paper className={classes.root}>
   
      <GridContainer style={{
        padding:'1em'
      }}>
        <GridItem xs={2} sm={2} md={2} lg={2}>
<TextField

           id="outlined-required"
           label="Invoice ID"
            value={invoiceno}
            onChange={(e)=>{
              setInvoiceNo(e.target.value)
              setPatientID("")
              setPatientName('')
              setMobileNumber('')
              setCnic('')
              setPassport('')
            }}
            variant="outlined"
          />
</GridItem>
<GridItem xs={2} sm={2} md={2} lg={2}>
<TextField

           id="outlined-required"
           label="ID"
            value={patient_id}
            onChange={(e)=>{
              setPatientID(e.target.value)
              setPatientName('')
              setMobileNumber('')
              setCnic('')
              setPassport('')
              setInvoiceNo('')
            }}
            variant="outlined"
          />
</GridItem>
<GridItem xs={2} sm={2} md={2} lg={2}>
<TextField
           id="outlined-required"
            label="Name"
            value={patient_name}
            onChange={(e) => {
              setPatientName(e.target.value);
              setPatientID('')
              setMobileNumber('')
              setCnic('')
              setPassport('')
              setInvoiceNo('')
            }}
            variant="outlined"
          />
</GridItem>
<GridItem xs={2} sm={2} md={2} lg={2}>
          <TextField
            id="outlined-required"
            value={mobile_number}
            onChange={(e) => {
              setMobileNumber(e.target.value);
              setPatientID('')
              setPatientName('')
              setCnic('')
              setPassport('')
              setInvoiceNo('')
            }}
            label="Patient Mobile"
            variant="outlined"
          />
</GridItem>
<GridItem xs={2} sm={2} md={2} lg={2}>
<TextField
            id="outlined-required"
            value={cnic}
            onChange={(e) => {
              setCnic(e.target.value);
              setPassport('')
              setCnic('')
              setPatientName('')
              setPatientID('')
              setMobileNumber('')
              setInvoiceNo('')
            }}
            label="CNIC"
            variant="outlined"
          />
</GridItem>
<GridItem xs={6} sm={6} md={2} lg={2}>
<TextField
            id="outlined-required"
            value={passport}
            onChange={(e) => {
              setPassport(e.target.value);
              setPatientID('')
              setPatientName('')
              setCnic('')
              setMobileNumber('')
              setInvoiceNo('')
            }}
            label="Passport"
            variant="outlined"
          />
</GridItem>
</GridContainer>
      <GridContainer style={{
        padding:'1em'
      }}>
      <GridItem xs={6} sm={6} md={2} lg={2}>
                
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
<KeyboardDateTimePicker
      variant="inline"
      inputVariant="outlined"
      label="From"
      ampm={false}
      minDate={new Date().setMonth(new Date().getMonth()-1)}
      format="dd/MM/yyyy"
      value={from}
      maxDate={to}
      InputAdornmentProps={{ position: "start" }}
      onChange={date=> {
        setFrom(date)}}
    />
    </MuiPickersUtilsProvider>
              </GridItem>
             
              
              <GridItem xs={6} sm={6} md={2} lg={2}>
               
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
<KeyboardDateTimePicker
      variant="inline"
      inputVariant="outlined"
      label="To"
      ampm={false}
      format="dd/MM/yyyy"
      maxDate={new Date()}
      value={to}
      minDate={from}
      InputAdornmentProps={{ position: "start" }}
      onChange={date=> setTo(date)}
    />
    </MuiPickersUtilsProvider>
                
              </GridItem>
              <GridItem xs={6} sm={6} md={2} lg={2}>
              <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" }}
            onClick={() => {
              setLoading(true)
              var url ="https://eviewer.fslabs.com.pk:5443/4DACTION/Web_PatientsCallingUpSearch"
              var vSearchStr={
                patient_id:patient_id+"",
            patient_name:patient_name,
            mobile_number:mobile_number,
            cnic:cnic+"",
            invoice_no:invoiceno+"",
            passport:passport,
            date_start:from.toISOString().split('.')[0],
            date_end:to.toISOString().split('.')[0]
            
              }
                fetch(url,{
                  method:"POST",
                  body:JSON.stringify(vSearchStr)
                }).then((res)=>res.json())
                 .then((result)=>{
                   console.log(result)
                  var the_rows=[]
                  result.map((data)=>{
                    var obj={ 
                      id:data.patient_id,
                      name:data.patient_name,
                      mobile:data.mobile_number,
                    passport:data.Passport,
                      cnic:data.cnic,
                      edit:<center>
                      <Button variant="contained" style={{backgroundColor:'green', color:'white'}} disableElevation onClick={()=>{
                    setPatientID(data.patient_id)    
                    setOpenEdit(true)
                    
                    
                    } }>
                       <  ReceiptIcon />
                   </Button>
            </center>
                   
                    }
                    the_rows.push(obj)
            
                  })
                  
                  setRows(the_rows)
                  setOrg_Rows(the_rows)
                  setLoading(false)
                 })
            }}
            className={classes.button}
          >
            Search
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "1em", marginLeft:'1em' }}
            onClick={() => {
              setPatientName('')
              setPatientID('')
              setMobileNumber('')
              setCnic('')
              setPassport('')
            }} >
              Clear
            </Button>
          </GridItem>
           
</GridContainer>

    
        <div align="right">
               <TextField id="standard-basic" label="Search"
              
              onChange={async (e)=>{
                  var lab_tests=[]
                  var text=e.target.value
                 var data=await org_rows.map((item)=>{
                   
                 
                   
                     if(item.name.toLowerCase().includes(text.toLowerCase())){
                       lab_tests.push(item)
                       return
                    
                   }
                   if(item.passport.toLowerCase().includes(text.toLowerCase())){
                    lab_tests.push(item)
                    return
                 
                }
                   if(item.cnic.toLowerCase().includes(text.toLowerCase())){
                     lab_tests.push(item)
                     return
                  
                 }
                 if(item.id.toLowerCase().includes(text.toLowerCase())){
                   lab_tests.push(item)
                   return
                
               }
                     
                     return item
             
                 })
                 Promise.all(data).then((item)=>{
                  
                    setRows(lab_tests)
                    
                 })
                 
           }}              
              />
               </div>
     {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
     <>
      <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code+Math.random()} >
                {columns.map((column) => {
                  const value = row[column.id];
                 
                  return (
                  <>
                     
                      <TableCell key={column.id} align={column.align}  >
                     {value}
                                      </TableCell>
                   
                   </>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    </>
     }
  
      
    </Paper>
    <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}

                  maxWidth={'xl'}
                  fullWidth={true}
                  open={OpenEdit}
                  TransitionComponent={Transition}
                  keepMounted
                  
                  onClose={() => {
                      setOpenEdit(false)
                 
                  }}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>Edit Patient</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    
                    {OpenEdit ?
                     <center> 
                     <Fragment key={"sqq"}>
                  <EditPatient patient_id={patient_id}/>
              </Fragment>
              </center>: null}
                 
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => {
                          setOpenEdit(false)
                       
                      }}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
                
   
  </>
  );
}
